import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useInput } from "react-admin";

interface Choice {
  [key: string]: any;
}

interface CustomSelectInputProps {
  source: string;
  label: string;
  choices: Choice[];
  optionText: string;
  optionValue: string;
}

export const SmartSelect: React.FC<CustomSelectInputProps> = ({
  source,
  label,
  choices,
  optionText,
  optionValue,
}) => {
  const {
    id,
    field: { onChange, value },
    fieldState: { error },
  } = useInput({ source });

  return (
    <FormControl fullWidth error={!!error} sx={{ maxWidth: "sm" }}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={onChange}
        label={label}
      >
        {choices.map((choice) => (
          <MenuItem key={choice[optionValue]} value={choice[optionValue]}>
            {choice[optionText]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
