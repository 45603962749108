export interface BaseRecord {
    id: string;
    [key: string]: any;
}

export type ContextProviderProps = {
    children: React.ReactNode;
};


export interface Location {
    type: string;
    coordinates: number[];
}

export interface Address {
    _id: string;
    creator: string;
    name: string;
    phone: string;
    province: string;
    city: string;
    district: string;
    address: string;
    deleted: boolean;
    location?: Location;
}

export interface Truck {
    _id: string;
    plate: string;
    plate_color: number;
    type: string;
    owner: string;
    createdAt: string;
    updatedAt: string;
    UpdatedAt: string;
    route_id: string;
    truck_category_id: string;
    phone: string;
}


export enum SourceType {
    CompanyApp = "公司App", // 公司应用
    WechatMiniProgram = "微信小程序", // 微信小程序
    OfflineCollection = "线下收集", // 线下收集
    Other = "其它" // 其它
}


export interface User {
    avatar: string;
    name: string;
}