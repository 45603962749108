import React, { useState } from "react";
import { Division } from "./division-input";
import { Select as MuiSelect, MenuItem, FormControl } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

export interface BaseOptionType {
  value: string;
  label: string;
  children?: BaseOptionType[];
}

interface CascaderProps {
  options: BaseOptionType[];
  onChange: (selectedOptions: Division) => void;
  defaultValue?: string[];
}

const Select: React.FC<{
  options: BaseOptionType[];
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
}> = ({ options, value, onChange }) => (
  <FormControl>
    <MuiSelect value={value} onChange={onChange} displayEmpty>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.label}>
          {option.label}
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
);

const Cascader: React.FC<CascaderProps> = ({
  options,
  onChange,
  defaultValue,
}) => {
  const [value, setValue] = useState<string[]>(
    Array.isArray(defaultValue) && defaultValue.length > 0
      ? defaultValue
      : ["", "", ""]
  );

  const findOption = (label: string, options: BaseOptionType[]) =>
    options.find((option) => option.label === label);

  const getFirstLabel = (options: BaseOptionType[]) => options[0]?.label || "";

  const handleChange = (event: SelectChangeEvent<string>, index: number) => {
    const newLabel = event.target.value;

    if (newLabel) {
      const newValue = [...value];
      newValue[index] = newLabel;

      if (index === 0) {
        const selectedOption = findOption(newLabel, options);
        newValue[1] = getFirstLabel(selectedOption?.children || []);
        newValue[2] = getFirstLabel(
          selectedOption?.children?.[0]?.children || []
        );
      } else if (index === 1) {
        const selectedOption = findOption(
          newLabel,
          findOption(value[0], options)?.children || []
        );
        newValue[2] = getFirstLabel(selectedOption?.children || []);
      }

      setValue(newValue);

      onChange({
        province: newValue[0],
        city: newValue[1],
        district: newValue[2],
      });
    }
  };

  const getOptions = (index: number) => {
    if (index === 0) {
      return options;
    } else if (index === 1) {
      return findOption(value[0], options)?.children || [];
    } else if (index === 2) {
      return (
        findOption(value[1], findOption(value[0], options)?.children || [])
          ?.children || []
      );
    } else {
      return [];
    }
  };

  return (
    <div>
      {value.map((v, index) => (
        <Select
          key={index}
          options={getOptions(index)}
          value={v}
          onChange={(event) => handleChange(event, index)}
        />
      ))}
    </div>
  );
};

export default Cascader;
