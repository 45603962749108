import React from "react";
import cascaderOptions, { DivisionUtil } from "@pansy/china-division";
import { Labeled, required, useInput } from "react-admin";
import Typography from "@mui/material/Typography/Typography";
import Cascader from "./cascader";

const divisionUtil = new DivisionUtil(cascaderOptions);

export interface Division {
  province: string;
  city: string;
  district: string;
}

interface Props {
  source: string;
  label: string;
  defaultValue?: [];
  onChange?: (value: Division | undefined) => void;
  value?: Division;
}

const DivisionInput: React.FC<Props> = ({ onChange, value, ...props }) => {
  const { field, fieldState } = useInput({
    ...props,
    validate: required(),
  });

  const handleChange = (selectedOptions: Division | undefined) => {
    field.onChange(selectedOptions);
    if (onChange) {
      onChange(selectedOptions);
    }
  };

  const defaultValue = field.value
    ? [field.value.province, field.value.city, field.value.district]
    : [];

  return (
    <div>
      <Labeled label={props.label}>
        <Cascader
          options={divisionUtil.getSourceData()}
          onChange={handleChange}
          defaultValue={defaultValue}
        />
      </Labeled>
      {fieldState.error && (
        <Typography variant="body2" color="error">
          请选择
        </Typography>
      )}
    </div>
  );
};

export default DivisionInput;
