import { FC } from "react";
import { FieldProps, TextInput, useRecordContext } from "react-admin";
import icons from "../../../styles/icons/icons";
import { PlateColor, TruckLength } from "../../../utils/truck-utils";
import { Field } from "../../components/create-dialog";
import DivisionInput from "../../components/division-input";

export const SourceIcon: FC<FieldProps> = () => {
  const record = useRecordContext();
  return record?.truck?.owner ? <icons.android /> : <icons.list />;
};

export const CustomField: FC<FieldProps> = ({ source }) => {
  const record = useRecordContext();
  let value = "";
  if (record && source) {
    switch (source) {
      case "truck.licensePlate":
        value = record.truck?.licensePlate
          ? record.truck.licensePlate
          : record.truck?.plate
          ? record.truck.plate
          : "";
        break;
      case "truck.truckType":
        value = record.truck?.truckType
          ? record.truck.truckType
          : record.truck?.type
          ? record.truck?.type
          : "";
        break;
      case "truck.truckLength":
        value = record.truck?.truckLength
          ? record.truck.truckLength
          : record.truck?.truck_length
          ? TruckLength(record.truck.truck_length)
          : "";
        break;
      case "truck.plateColor":
        value = record.truck?.plateColor
          ? record.truck.plateColor
          : record.truck?.plate_color
          ? PlateColor(record.truck.plate_color)
          : "";
        break;
      case "truck_length":
        value = TruckLength(record[source]);
        break;
      case "plate_color":
        value = PlateColor(record[source]);
        break;
      default:
        value = record[source] || "";
        break;
    }
  }

  return <span>{value}</span>;
};

export const createStationsFilterQuery = (searchText: any) => ({
  name: `%${searchText}%`,
});

export const createTrucksFilterQuery = (searchText: any) => ({
  truck: `%${searchText}%`,
});

export const createStationFields: Field[] = [
  { name: "stationName", label: "名称", required: true, component: TextInput },
  { name: "location", label: "地址", component: DivisionInput },
];

export const createTagFields: Field[] = [
  { name: "tagName", label: "名称", required: true, component: TextInput },
];
