import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  SimpleShowLayout,
  Show,
  ArrayField,
  FunctionField,
  SavedQueriesList,
  FilterLiveSearch,
  WithListContext,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
} from "react-admin";
import { Driver } from "../models/Driver";
import { PlateColor, TruckLength, TruckType } from "../utils/truck-utils";
import { CardContent, Chip, Stack } from "@mui/material";
import DivisionInput from "./components/division-input";
import { AsideStyledCard } from "./components/common";
import { CustomField } from "./fleets/fleets/common";
import { formatDate } from "../utils/common";

const Aside = () => (
  <AsideStyledCard>
    <CardContent>
      <FilterLiveSearch source="name" label="姓名" alwaysOn />
      <FilterLiveSearch source="phone" label="手机号" alwaysOn />
      <FilterLiveSearch source="trucks.0.plate" label="车牌" alwaysOn />
      <FilterLiveSearch source="trucks.0.type" label="车型" alwaysOn />
      <SavedQueriesList />
    </CardContent>
  </AsideStyledCard>
);

export const DriverList: React.FC = (props) => (
  <List {...props} aside={<Aside />}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" label="姓名" />
      <TextField source="phone" label="手机号" />
      <TextField source="trucks.0.plate" label="车牌" />
      <TextField source="trucks.0.type" label="车型(旧)" />
      <ArrayField source="subsRoutes" label="订阅专线">
        <WithListContext
          render={({ data }) => (
            <Stack direction="row" spacing={1}>
              {data.map((_) => (
                <Chip key={_._id} label={`${_.from.city} - ${_.to.city}`} />
              ))}
            </Stack>
          )}
        />
      </ArrayField>
      <FunctionField
        source="trucks.0.plate_color"
        label="车牌颜色"
        render={(record: Driver) =>
          PlateColor(record?.trucks?.[0]?.plate_color)
        }
      />
      <FunctionField
        source="trucks.0.truck_type"
        label="车型（新）"
        render={(record: Driver) => TruckType(record?.trucks?.[0]?.truck_type)}
      />
      <FunctionField
        source="trucks.0.truck_length"
        label="车长（新）"
        render={(record: Driver) =>
          TruckLength(record?.trucks?.[0]?.truck_length)
        }
      />
    </Datagrid>
  </List>
);

export const DriverEdit: React.FC = (props) => {
  return (
    <Edit
      {...props}
      transform={(_: { [x: string]: any; lastPosition: any }) => {
        const { id, subsRoutes } = _;
        return { id, subsRoutes };
      }}
    >
      <SimpleForm>
        <TextField source="name" label="姓名" />
        <FormDataConsumer>
          {({ formData }) =>
            formData.subsRoutes ? (
              <ArrayInput source="subsRoutes" label="订阅专线">
                <SimpleFormIterator inline>
                  <DivisionInput source="from" label="起点" />
                  <DivisionInput source="to" label="终点" />
                </SimpleFormIterator>
              </ArrayInput>
            ) : (
              <span />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export const DriverShow: React.FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="姓名" />
      <TextField source="nickname" label="昵称" />
      <TextField source="phone" label="手机号" />
      <ArrayField source="trucks" label="货车">
        <Datagrid bulkActionButtons={false}>
          <TextField source="plate" label="车牌" />
          <TextField source="type" label="车型" />
          <CustomField source="truck_length" label="车长" />
          <CustomField source="plate_color" label="车牌颜色" />
        </Datagrid>
      </ArrayField>
      <FunctionField
        label="订阅专线"
        render={(record: any) =>
          record.subsRoutes && record.subsRoutes.length > 0
            ? record.subsRoutes
                .map((route: any) => `${route.from.city} - ${route.to.city}`)
                .join(", ")
            : "无"
        }
      />
      <FunctionField
        label="总运单数"
        render={(record: any) => record.waybills?.length || 0}
      />
      <FunctionField
        label="最新位置"
        render={(record: any) =>
          record.positions && record.positions.length > 0
            ? `${record.positions[record.positions.length - 1].province} ${
                record.positions[record.positions.length - 1].city
              } (更新时间: 
                
                ${formatDate(
                  record.positions[record.positions.length - 1].updatedAt
                )})`
            : "无"
        }
      />
    </SimpleShowLayout>
  </Show>
);
