import React from "react";
import { Cargo } from "../../../models/Cargo";
import { i18nProvider } from "../../../providers/i18nProvider";

export const calculateTimeDiff = (updatedAt: Date) => {
    const now = new Date();
    const diffMs = Math.abs(now.getTime() - updatedAt.getTime());
    const diffHours = Math.round(diffMs / (1000 * 60 * 60));
    const diffMinutes = Math.round(diffMs / (1000 * 60));
    return diffHours < 1 ? `${diffMinutes} 分钟前` : `${diffHours} 小时前`;
};

export const calculateDistance = (distance: number) => (distance / 1000).toFixed(2);

export const renderRecord = (record: any) => {
    let result = "";

    if (record.distance && record.positions && record.positions.length > 0) {
        const updatedAt = new Date(
            record.positions[record.positions.length - 1].updatedAt
        );
        const timeDiff = calculateTimeDiff(updatedAt);
        const distanceKm = calculateDistance(record.distance);
        result += `${timeDiff} 距离 ${distanceKm} 千米`;
    }

    if (record.matchedOrders) {
        const orderCount = record.matchedOrders;
        result += result ? ` | 运过 ${orderCount} 单` : `运过 ${orderCount} 单`;
    }

    return result;
};

export const useDialog = (initialOpen: boolean) => {
    const [open, setOpen] = React.useState(initialOpen);

    const handleClickOpen = React.useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);

    return { open, handleClickOpen, handleClose };
};


export const DEFAULT_TITLE = "货源推荐";
export const DEFAULT_PHONE_NUMBER = "1xxxxxxxxxx";
export const DEFAULT_SMS_TEMPLATE = 4;
export const DEFAULT_TRUCKS_INFO = "x辆xx";

// 生成短信内容
export const generateSmsContent = (
    selectedCargo: Cargo,
    phoneNumber: string,
    smsTemplate: number,
    trucksInfo: string
) => {
    const { from, to, name, single_price, unit_price } = selectedCargo;
    const dateObj = new Date();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const formattedDate = `${month}月${day}日`;

    const smsContent =
        smsTemplate === 3
            ? `${formattedDate}优质货源发布！${from.city}发往${to.city
            }的${name}，运费${single_price ?? unit_price
            }元，机会就在眼前，快联系桂运宝${phoneNumber}!`
            : `桂运宝货运平台${from.city}发往${to.city}的${name}，需要${trucksInfo}车，装货联系${phoneNumber}，平台下载 https://next.guiyunbao.cn 。`;

    const smsVariables = {
        date: formattedDate,
        departure: from.city,
        from: from.city,
        destination: to.city,
        to: to.city,
        cargo: name,
        fee: single_price ?? unit_price,
        contact: phoneNumber,
        trucks: trucksInfo,
        phone: phoneNumber,
    };

    return { smsContent, smsVariables, trucks: trucksInfo };
};

// 处理推送错误
export const handlePushErrors = (pushResults: any, notify: any) => {
    const failureReasons = pushResults.data
        .filter(
            (item: { status: string; reason: string }) => item.status === "failure"
        )
        .map(
            (item: { userId: string; status: string; reason: string }) =>
                `${item.userId}: ${item.reason}`
        );

    if (failureReasons.length === 0) {
        notify("pushSucceeded", { type: "success" });
    } else if (failureReasons.length === pushResults.data.length) {
        notify("pushFailed", {
            type: "error",
            message: `${i18nProvider.translate("pushFailed")}: ${failureReasons.join(
                ", "
            )}`,
        });
    } else {
        notify("pushPartiallyFailed", {
            type: "warning",
            message: `${i18nProvider.translate(
                "pushPartiallyFailed"
            )}: ${failureReasons.join(", ")}`,
        });
    }
};