import chineseMessages from "@haxqer/ra-language-chinese";
import polyglotI18nProvider from "ra-i18n-polyglot";

const customMessages = {
    "pushSucceeded": "推送成功",
    "pushFailed": "推送失败",
    "pushPartiallyFailed": "部分推送失败",
    "stationCreated": "站点创建成功",
    "stationCreateFailed": "站点创建失败",
    "invalidPhoneNumber": "请输入有效的联系电话",
    "tagCreated": "标签创建成功",
    "tagCreateFailed": "标签创建失败",
    // Other custom translations...
};

const messages: { [key: string]: any } = {
    'zh_CN': {
        ...chineseMessages,
        ...customMessages,
    },
    // Other languages...
};

export const i18nProvider = polyglotI18nProvider(locale => messages[locale], "zh_CN");