import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  CreateButton,
  TopToolbar,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  FieldProps,
  useRecordContext,
  FilterLiveSearch,
  SavedQueriesList,
} from "react-admin";
import { Link } from "react-router-dom";
import { AsideStyledCard } from "../../components/common";
import { CardContent } from "@mui/material";

const ListActions = (props: any) => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const MyChipField: React.FC<FieldProps> = () => {
  const record = useRecordContext();

  return (
    <Link to={`/stations/${record.fleetId}/${record.id}/show`}>
      <ChipField source="name" />
    </Link>
  );
};

const Aside = () => (
  <AsideStyledCard>
    <CardContent>
      <FilterLiveSearch source="name" label="车队名称" alwaysOn />
      <FilterLiveSearch source="description" label="车队描述" alwaysOn />
      <SavedQueriesList />
    </CardContent>
  </AsideStyledCard>
);

export const FleetList: React.FC = (props: any) => {
  return (
    <List {...props} actions={<ListActions />} aside={<Aside />}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" label="车队名称" />
        <TextField source="description" label="车队描述" />
        <TextField source="branch.name" label="所属分公司" />
        <ReferenceManyField
          label="站点"
          reference={`fleets/stations`}
          target="fleetId"
        >
          <SingleFieldList linkType={false}>
            <MyChipField />
          </SingleFieldList>
        </ReferenceManyField>
        <EditButton />
      </Datagrid>
    </List>
  );
};







