import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { MenuItemLink } from "react-admin";
import icons from "../../styles/icons/icons";
import { useNavigate } from "react-router-dom";

interface NestedMenuItemProps {
  name: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}

interface NestedMenuItemLinkProps {
  to: string;
  primaryText: string;
  leftIcon: React.ReactNode;
}

const NestedMenuItem: React.FC<NestedMenuItemProps> = ({
  name,
  icon,
  children,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

const NestedMenuItemLink: React.FC<NestedMenuItemLinkProps> = ({
  to,
  primaryText,
  leftIcon,
}) => (
  <ListItemButton sx={{ pl: 5 }}>
    <MenuItemLink
      to={to}
      primaryText={primaryText}
      leftIcon={React.isValidElement(leftIcon) ? leftIcon : undefined}
    />
  </ListItemButton>
);
export default function NestedMenu() {
  let navigate = useNavigate();

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
    >
      <ListItemButton onClick={() => navigate("/schedulings")}>
        <ListItemIcon>
          <icons.scheduling />
        </ListItemIcon>
        <ListItemText primary="调度" />
        <ListItemIcon />
      </ListItemButton>

      <NestedMenuItem name="App" icon={<icons.app />}>
        <NestedMenuItemLink
          to="/drivers"
          primaryText="App司机"
          leftIcon={<icons.drivers />}
        />
        <NestedMenuItemLink
          to="/validCargos"
          primaryText="App货物"
          leftIcon={<icons.cargos />}
        />
        <NestedMenuItemLink
          to="/waybills"
          primaryText="App运单"
          leftIcon={<icons.orders />}
        />
      </NestedMenuItem>

      <NestedMenuItem name="车队" icon={<icons.group />}>
        <NestedMenuItemLink
          to="/fleets"
          primaryText="车队"
          leftIcon={<icons.group />}
        />
        <NestedMenuItemLink
          to="/stations"
          primaryText="站点"
          leftIcon={<icons.station />}
        />
        <NestedMenuItemLink
          to="/msDrivers"
          primaryText="多渠道司机"
          leftIcon={<icons.msDrivers />}
        />
        <NestedMenuItemLink
          to="/msTrucks"
          primaryText="多渠道货车"
          leftIcon={<icons.msTrucks />}
        />
      </NestedMenuItem>

      <ListItemButton onClick={() => navigate("/reserveCapacity")}>
        <ListItemIcon>
          <icons.reserve />
        </ListItemIcon>
        <ListItemText primary="储备运力" />
        <ListItemIcon />
      </ListItemButton>

      <NestedMenuItem name="推广" icon={<icons.notification />}>
        <NestedMenuItemLink
          to="/push"
          primaryText="推送记录"
          leftIcon={<icons.history />}
        />
      </NestedMenuItem>
      <NestedMenuItem name="统计" icon={<icons.analytics />}>
        <NestedMenuItemLink
          to="/users-cargo-stats"
          primaryText="发货统计"
          leftIcon={<icons.shoppingCart />}
        />
      </NestedMenuItem>
    </List>
  );
}
