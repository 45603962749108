import { FleetStationShow } from "../views/fleets/stations";
import UserCargoStats from "../views/stats/user-cargo-stats";
import UserCargoStatsShow from "../views/stats/user-cargo-stats-show";

export const customRoutes = [
  {
    path: "/stations/:fleetId/:stationId/show",
    component: FleetStationShow,
  },
  {
    path: "/users-cargo-stats",
    component: UserCargoStats,
  },
  {
    path: "/user-cargo-stats/:id",
    component: UserCargoStatsShow,
  }
  // Add more custom routes here as needed
];