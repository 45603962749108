import { BooleanInput, Filter } from "react-admin";

export const CargoMatchFilters = (props: any) => {
  return (
    <Filter {...props}>
      <BooleanInput
        source="cargoMatch"
        label="货物匹配"
        defaultValue={false}
        alwaysOn
      />
    </Filter>
  );
};
