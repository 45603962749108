import {
  BooleanField,
  Datagrid,
  Filter,
  FunctionField,
  Identifier,
  Labeled,
  List,
  RaRecord,
  ReferenceField,
  TextField,
  TextInput,
} from "react-admin";
import CargoContext from "../../contexts/CargoContext";
import React, { useCallback, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Paper } from "@mui/material";
import { formatDate } from "../../utils/common";
import { Cargo } from "../../models/Cargo";
import { TruncatedTextField, UserAvatarWithName } from "./common";
import { User } from "../../models/common";
import { SmallCargoCard } from "./small-cargo-card";

interface CargoFilterProps {
  [key: string]: any;
}

const CargoFilter: React.FC<CargoFilterProps> = (props) => (
  <Filter {...props}>
    <TextInput source="name" label="货物名搜索" alwaysOn autoComplete="name" />
  </Filter>
);

const CargoDetail: React.FC = () => {
  return (
    <Paper elevation={3} style={{ padding: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Labeled>
            <FunctionField
              source="unit_price"
              label="运输单价"
              render={(record: any) => `${record?.unit_price} 元`}
            />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled>
            <FunctionField
              source="deposit"
              label="押金"
              render={(record: any) => `${(record?.deposit || 0) / 100} 元`}
            />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled label="是否退还押金">
            <BooleanField source="deposit_refund" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled label="是否是长期货源">
            <BooleanField source="continuous" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled label="一口价">
            <TextField source="single_price" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled label="无预付款">
            <BooleanField source="no_pre_billing" />
          </Labeled>
        </Grid>
        <Grid item xs={3}>
          <Labeled label="货物描述">
            <TruncatedTextField source="description" />
          </Labeled>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const CargoList: React.FC = () => {
  const { setSelectedCargo } = React.useContext(CargoContext);
  const [selectedRow, setSelectedRow] = useState<Identifier | null>(null);

  const handleRowClick = useCallback(
    (id: Identifier, resource: string, record: RaRecord) => {
      setSelectedCargo(record as Cargo);
      setSelectedRow(id);
      return false;
    },
    [setSelectedCargo, setSelectedRow]
  );

  const theme = useTheme();

  const rowSx = useCallback(
    (record: RaRecord) => ({
      backgroundColor:
        record.id === selectedRow
          ? theme.palette.action.selected
          : theme.palette.background.paper,
    }),
    [selectedRow, theme]
  );

  return (
    <List
      resource="validCargos"
      sort={{ field: "id", order: "DESC" }}
      filters={<CargoFilter />}
      perPage={10}
      disableSyncWithLocation
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={handleRowClick}
        expand={<CargoDetail />}
        rowSx={rowSx}
      >
        <FunctionField
          label="货物"
          sortBy="name"
          render={(record: Cargo) => SmallCargoCard(record)}
        />
        <FunctionField
          label="发布/更新时间"
          sortBy="updatedAt"
          render={(record: Cargo) => formatDate(record.updatedAt)}
        />
        <FunctionField
          label="装车时间"
          sortBy="expect_departure_at"
          render={(record: Cargo) => formatDate(record.expect_departure_at)}
        />
        <ReferenceField
          reference="users"
          label="货主"
          source="owner"
          sortBy="name"
        >
          <FunctionField
            render={(record: User) => <UserAvatarWithName record={record} />}
          />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
