import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React from "react";
import { useMutation, MutationFunction } from "react-query";

export interface Field {
  name: string;
  label: string;
  required?: boolean;
  component: React.ComponentType<any>; 
}

interface CreateDialogProps {
  open: boolean;
  onClose: () => void;
  mutationFn: MutationFunction<any, any>; 
  fields: Field[];
  title: string;
}

export const CreateDialog: React.FC<CreateDialogProps> = ({
  open,
  onClose,
  mutationFn,
  fields,
  title,
}) => {
  const mutation = useMutation(mutationFn);
  const [formValues, setFormValues] = React.useState<Record<string, any>>({});

  const handleChange = (name: string, value: any) => {
    if (value && value.target) {
      value = value.target.value;
    }
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutation.mutate(formValues);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {fields.map((field) => {
            const Component = field.component;
            return (
              <Component
                key={field.name}
                name={field.name}
                label={field.label}
                fullWidth
                required={field.required}
                value={formValues[field.name]}
                onChange={(value: any) => handleChange(field.name, value)}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary" disabled={mutation.isLoading}>
            创建
          </Button>
          <Button onClick={onClose} color="secondary">
            取消
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
