import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemText,
  styled,
} from "@mui/material";
import React from "react";
import icons from "../../../styles/icons/icons";

interface ExpandMoreProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps & IconButtonProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const FleetGuideCard = () => {
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ margin: 2 }}>
      <CardHeader
        title="车队引导"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <icons.expandMore />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <List component="nav" aria-label="main mailbox folders">
            <ListItem>
              <ListItemText primary='1. 在"多渠道司机"子菜单中创建司机。' />
            </ListItem>
            <ListItem>
              <ListItemText primary='2. 在"多渠道货车"子菜单中创建货车，并将新创建的司机与货车绑定在一起。' />
            </ListItem>
            <ListItem>
              <ListItemText primary="3. 在车队的创建和编辑页面中，从列表中选择你刚刚创建并绑定的司机和货车组合。" />
            </ListItem>
            <ListItem>
              <ListItemText primary="4. 在创建站点信息后，你可以在车队站点选择列表中添加站点。" />
            </ListItem>
            <ListItem>
              <ListItemText primary="5. 如果你需要快速创建站点，你可以直接在车队的创建和编辑页面中进行。" />
            </ListItem>
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
};
