import React, { useState } from "react";
import { Modal, Box, Button } from "@mui/material";
import { CargoList } from "./cargo-list";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "70vh",
  overflow: "auto",
};

const CargoSelector: React.FC = () => {
  const [open, setOpen] = useState(false);

  const openModal = (): void => {
    setOpen(true);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={openModal}
        style={{ marginTop: "8px", marginBottom: "8px" }}
      >
        选中/切换货源
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="cargo-list-modal-title"
        aria-describedby="cargo-list-modal-description"
      >
        <Box sx={style}>
          <CargoList />
        </Box>
      </Modal>
    </div>
  );
};

export const SwitchCargo: React.FC = () => {
  return <CargoSelector />;
};
