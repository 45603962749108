import {
  TextField,
  useDataProvider,
  Loading,
  List,
  Datagrid,
  EditButton,
  Show,
  SimpleShowLayout,
  FilterLiveSearch,
  SavedQueriesList,
  required,
} from "react-admin";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Create, Edit, SimpleForm, TextInput } from "react-admin";
import DivisionInput from "../components/division-input";
import { AsideStyledCard } from "../components/common";
import { CardContent } from "@mui/material";

const Aside = () => (
  <AsideStyledCard>
    <CardContent>
      <FilterLiveSearch source="name" label="站点名称" alwaysOn />
      <FilterLiveSearch source="location.province" label="省份" alwaysOn />
      <FilterLiveSearch source="location.city" label="城市" alwaysOn />
      <FilterLiveSearch source="location.district" label="区域" alwaysOn />
      <SavedQueriesList />
    </CardContent>
  </AsideStyledCard>
);

export const StationList: React.FC = (props) => {
  return (
    <List {...props} aside={<Aside />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label="站点名称" />
        <TextField source="location.province" label="省份" />
        <TextField source="location.city" label="城市" />
        <TextField source="location.district" label="区域" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const StationCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="名称" validate={required()} />
      <DivisionInput source="location" label="地址" />
    </SimpleForm>
  </Create>
);

export const StationEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" label="名称" validate={required()} />
      <DivisionInput source="location" label="地址" />
    </SimpleForm>
  </Edit>
);

export const StationShow: React.FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="名称" />
      <TextField source="location.province" label="省份" />
      <TextField source="location.city" label="城市" />
      <TextField source="location.district" label="区域" />
    </SimpleShowLayout>
  </Show>
);

export const FleetStationShow = () => {
  const dataProvider = useDataProvider();
  const { fleetId, stationId } = useParams();
  const { data, isLoading, error } = useQuery(
    ["station", fleetId, stationId],
    () =>
      dataProvider.getOneWithSubResource(
        "fleets",
        fleetId,
        "stations",
        stationId
      ),
    { enabled: Boolean(fleetId && stationId) }
  );

  if (isLoading) return <Loading />;
  if (error) {
    return <p>ERROR</p>;
  }
  if (!data) return <p>无数据</p>;
  return (
    <div>
      <p>{data.data.name}</p>
      {/* Add more fields here as needed */}
    </div>
  );
};
