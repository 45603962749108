import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { ShowButton } from "react-admin";
import { Cargo } from "../../models/Cargo";
import { TruckLength, TruckType } from "../../utils/truck-utils";

export const SmallCargoCard: React.FC<Cargo> = (record) => {
  return (
    <Card
      sx={{
        maxHeight: 120,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <Box display="flex">
        <CardMedia
          component="img"
          image={record.image}
          alt={record.name}
          style={{
            width: "104px",
            height: "104px",
            padding: "8px",
            objectFit: "cover",
          }}
        />
        <Box display="flex" flexDirection="column" maxHeight={100}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography variant="caption">
              {record.name} | {record.from.city} → {record.to.city}
            </Typography>
            <br />
            {record.truck_lengths &&
              record.truck_lengths.length > 0 &&
              record.truck_types &&
              record.truck_types.length > 0 && (
                <Typography variant="caption" color="textSecondary">
                  {`车长: ${record.truck_lengths
                    .map(TruckLength)
                    .join(", ")} | 车型: ${record.truck_types
                    .map(TruckType)
                    .join(", ")}`}
                </Typography>
              )}
          </CardContent>
        </Box>
      </Box>
      <ShowButton
        sx={{
          position: "absolute",
          right: 8,
          bottom: 8,
        }}
      />
    </Card>
  );
};
