import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import {
  FunctionField,
  ReferenceField,
} from "react-admin";
import { Cargo } from "../../models/Cargo";
import { User } from "../../models/common";
import { formatDate } from "../../utils/common";
import { TruckLength, TruckType } from "../../utils/truck-utils";
import { UserAvatarWithName } from "./common";

interface CargoCardProps {
  cargo: Cargo;
}

const CargoDetail = ({
  label,
  content,
}: {
  label: string;
  content: React.ReactNode;
}) => (
  <Grid item xs={3}>
    <Typography variant="body2" color="textSecondary">
      {label}: <br />
      {content}
    </Typography>
  </Grid>
);

export const CargoCard: React.FC<CargoCardProps> = ({ cargo }) => (
  <Card sx={{ maxWidth: 600 }}>
    <CardActionArea>
      <CardMedia
        component="img"
        height="140"
        image={cargo?.image}
        alt="货物图片"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {cargo?.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {cargo?.description}
        </Typography>
        <ReferenceField
          reference="users"
          label="货主"
          source="owner"
          sortBy="name"
        >
          <FunctionField
            render={(record: User) => <UserAvatarWithName record={record} />}
          />
        </ReferenceField>
        <Grid container spacing={2}>
          <CargoDetail
            label="发布/更新时间"
            content={formatDate(cargo?.updatedAt)}
          />
          <CargoDetail label="发货地详细地址" content={cargo.from.address} />
          <CargoDetail label="收货地详细地址" content={cargo.to.address} />
          <CargoDetail
            label="装车时间"
            content={formatDate(cargo?.expect_departure_at)}
          />
          <CargoDetail label="运输单价" content={`${cargo?.unit_price} 元`} />
          <CargoDetail
            label="押金"
            content={`${(cargo?.deposit || 0) / 100} 元`}
          />
          <CargoDetail
            label="是否退还押金"
            content={cargo.deposit_refund ? "是" : "否"}
          />
          <CargoDetail
            label="是否是长期货源"
            content={cargo.continuous ? "是" : "否"}
          />
          {cargo.single_price && (
            <CargoDetail label="一口价/元" content={cargo.single_price} />
          )}
          <CargoDetail
            label="无预付款"
            content={cargo.no_pre_billing ? "是" : "否"}
          />
          <CargoDetail
            label="需求车长"
            content={
              cargo.truck_lengths?.length > 0
                ? cargo.truck_lengths.map(TruckLength).join(", ")
                : "无"
            }
          />
          <CargoDetail
            label="需求车型"
            content={
              cargo.truck_types?.length > 0
                ? cargo.truck_types.map(TruckType).join(", ")
                : "无"
            }
          />
        </Grid>
      </CardContent>
    </CardActionArea>
  </Card>
);
