import { Alert, Button } from "@mui/material";
import React, { useState } from "react";
import {
  AutocompleteArrayInput,
  Create,
  Datagrid,
  ReferenceArrayInput,
  ReferenceManyField,
  required,
  TabbedForm,
  TextField,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import UserContext from "../../../contexts/UserContext";
import { CreateDialog } from "../../components/create-dialog";
import {
  createStationFields,
  createStationsFilterQuery,
  createTrucksFilterQuery,
} from "./common";
import { FleetGuideCard } from "./fleet-guide-card";

export const FleetCreate: React.FC = (props) => {
  const { branchId } = React.useContext(UserContext);
  const transform = (data: any) => ({
    ...data,
    branch: branchId,
  });

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const createStation = async (data: any) => {
    const { data: newStation } = await dataProvider.create("stations", {
      data: { name: data.stationName, location: data.location },
    });
    if (newStation) {
      notify("stationCreated", { type: "success" });
      handleDialogClose();
      refresh();
    } else {
      notify("stationCreateFailed", { type: "error" });
    }
  };

  return (
    <Create {...props} transform={transform} aside={<FleetGuideCard />}>
      <TabbedForm>
        <TabbedForm.Tab label="基本信息">
          <TextInput source="name" label="车队名称" validate={required()} />
          <TextInput source="description" label="车队描述" />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="站点">
          <ReferenceManyField
            label="站点"
            reference={`fleets/stations`}
            target="fleetId"
          >
            <Datagrid>
              <TextField source="name" label="站点名称" />
              <TextField source="location.province" label="省份" />
              <TextField source="location.city" label="城市" />
              <TextField source="location.district" label="区域" />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceArrayInput source="stations" reference="stations">
            <AutocompleteArrayInput
              fullWidth
              optionText="name"
              label="添加站点"
              noOptionsText="无选项"
              filterToQuery={createStationsFilterQuery}
            />
          </ReferenceArrayInput>
          <Alert severity="info">
            没有找到合适的站点？
            <Button onClick={handleDialogOpen}>创建站点</Button>
          </Alert>

          <CreateDialog
            open={dialogOpen}
            onClose={handleDialogClose}
            mutationFn={createStation}
            title="创建站点"
            fields={createStationFields}
          />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="司机与货车">
          <Alert severity="info">只有绑定了货车的司机才能加入车队</Alert>
          <ReferenceManyField
            label="司机"
            reference={`fleets/assignment`}
            target="fleetId"
          >
            <Datagrid bulkActionButtons={false}>
              <TextField source="driver.name" label="名称" />
              <TextField source="driver.phone" label="手机" />
              <TextField source="truck.licensePlate" label="车牌" />
              <TextField source="truck.plateColor" label="车牌颜色" />
              <TextField source="truck.truckType" label="车型" />
              <TextField source="truck.truckLength" label="车长" />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceArrayInput
            source="fleetAssignments"
            reference="fleetAssignments"
          >
            <AutocompleteArrayInput
              fullWidth
              optionText={(option) =>
                `${option.truck.licensePlate ?? option.truck.plate} - ${
                  option.driver === null ? "未知司机" : option.driver.name
                } - ${option.source ?? `线下收集`}`
              }
              label="添加司机与货车（可输入车牌号快速查找）"
              filterToQuery={createTrucksFilterQuery}
              noOptionsText="无选项"
            />
          </ReferenceArrayInput>
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};
