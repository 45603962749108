import { SourceType } from "./common";

export enum TruckPlateColor {
    Blue = "蓝色",
    Yellow = "黄色",
    Black = "黑色",
    White = "白色",
    Green = "绿色",
}

export enum TruckLength {
    L4_2 = "4.2米",
    L6_8 = "6.8米",
    L9_6 = "9.6米",
    L13 = "13米",
    L17_5 = "17.5米",
    L8_7 = "8.7米",
    L13_75 = "13.75米",
}

export enum TruckType {
    PingBan = "平板",
    GaoLan = "高栏",
    XiangShi = "厢式",
    ZiXie = "自卸",
    LengCang = "冷藏",
    PaTi = "爬梯",
    JiZhuangXiang = "集装箱",
    GaoDiBan = "高低板",
}



export interface IMSTruck {
    model: string;
    licensePlate: string;
    plateColor: TruckPlateColor;
    truckType: TruckType;
    truckLength: TruckLength;
    source: SourceType;
    drivers: string[];
}