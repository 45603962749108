import React from "react";

const UserContext = React.createContext({
  userId: null as string | null,
  setUserId: (id: React.SetStateAction<string | null>) => {},
  branch: null as string | null,
  setBranch: (branch: React.SetStateAction<string | null>) => {},
  branchId: null as string | null,
  setBranchId: (branchId: React.SetStateAction<string | null>) => {},
});

export default UserContext;
