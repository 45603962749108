import React from "react";
import { Identifier, useDataProvider } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField as MuiTextField,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { Alert } from "@mui/material";
import { useDialog } from "./utils";
import CargoContext from "../../../contexts/CargoContext";
import { usePushNotification } from "./use-push-notifications";
import { AppPushStrategy, SmsPushStrategy } from "../../../utils/push-strategy";

interface PushDialogProps {
  selectedIds: Identifier[];
  initialPushStrategy?: string;
  availableStrategies?: string[];
}

export const PushDialog: React.FC<PushDialogProps> = React.memo(
  ({
    selectedIds,
    initialPushStrategy = "app",
    availableStrategies = ["app", "sms"],
  }) => {
    const strategyOptions = [
      { value: "app", label: "APP推送" },
      { value: "sms", label: "短信推送" },
    ];

    const dataProvider = useDataProvider();
    const { selectedCargo } = React.useContext(CargoContext);
    const { open, handleClickOpen, handleClose } = useDialog(false);
    const [selectedPushStrategy, setSelectedPushStrategy] =
      React.useState(initialPushStrategy);

    const filteredStrategyOptions = strategyOptions.filter((option) =>
      availableStrategies.includes(option.value)
    );

    const pushStrategy = React.useMemo(() => {
      return selectedPushStrategy === "app"
        ? new AppPushStrategy(dataProvider)
        : new SmsPushStrategy(dataProvider);
    }, [dataProvider, selectedPushStrategy]);

    const {
      title,
      setTitle,
      appPushContent,
      setAppPushContent,
      handlePush,
      smsContent,
      setSmsContent,
      smsTemplate,
      setSmsTemplate,
      phoneNumber,
      setPhoneNumber,
      trucksInfo,
      setTrucksInfo,
    } = usePushNotification(
      selectedIds,
      selectedCargo!,
      handleClose,
      pushStrategy,
      selectedPushStrategy
    );

    const handleStrategyChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setSelectedPushStrategy(event.target.value);
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(event.target.value);
    };

    const handleAppPushContent = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setAppPushContent(event.target.value);
    };

    const handleSmsContentChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setSmsContent(event.target.value);
    };

    const handlePhoneNumberChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setPhoneNumber(event.target.value);
    };

    const handleSmsTemplateChange = (event: SelectChangeEvent<number>) => {
      setSmsTemplate(+event.target.value);
    };

    const handleTrucksInfoChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setTrucksInfo(event.target.value);
    };

    return (
      <div>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          批量推送
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">批量推送</DialogTitle>
          <DialogContent>
            <RadioGroup
              row
              aria-label="pushStrategy"
              name="pushStrategy"
              value={selectedPushStrategy}
              onChange={handleStrategyChange}
            >
              {filteredStrategyOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
            {selectedPushStrategy === "sms" && (
              <>
                <Alert severity="info">
                  温馨提示：短信推送服务每条短信将产生0.055元的额外费用，请您谨慎使用。
                  <br />
                  短信推送暂时不支持自动导入联系人与货车需求，请您手动输入。
                </Alert>
                <Select
                  value={smsTemplate}
                  onChange={handleSmsTemplateChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={3}>模板 3</MenuItem>
                  <MenuItem value={4}>模板 4</MenuItem>
                </Select>
              </>
            )}
            {selectedPushStrategy !== "sms" && (
              <MuiTextField
                autoFocus
                margin="dense"
                label="标题"
                type="text"
                fullWidth
                value={title}
                onChange={handleTitleChange}
              />
            )}
            {selectedPushStrategy === "sms" ? (
              <MuiTextField
                margin="dense"
                label="短信内容"
                type="text"
                fullWidth
                value={smsContent}
                multiline={true}
                onChange={handleSmsContentChange}
              />
            ) : (
              <MuiTextField
                margin="dense"
                label="推送内容"
                type="text"
                fullWidth
                value={appPushContent}
                multiline={true}
                onChange={handleAppPushContent}
              />
            )}
            {selectedPushStrategy === "sms" && smsTemplate === 4 && (
              <MuiTextField
                margin="dense"
                label="货车需求"
                type="text"
                fullWidth
                value={trucksInfo}
                onChange={handleTrucksInfoChange}
              />
            )}
            {selectedPushStrategy === "sms" && (
              <MuiTextField
                margin="dense"
                label="联系电话"
                type="text"
                fullWidth
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            )}
            <Alert severity="info">
              若因推送过于频繁而导致的错误将不记入推送记录中。
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handlePush}>
              确认推送
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);
