import {
  Datagrid,
  Filter,
  FunctionField,
  Identifier,
  List,
  NumberInput,
  TextField,
  useListContext,
} from "react-admin";
import { User } from "../../models/common";
import { UserAvatarWithName } from "../components/common";
import { Card, CardContent, styled, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

const UserCargoStatsFilter: React.FC = (props) => (
  <Filter {...props}>
    <NumberInput label="天数" source="days" alwaysOn min={0} max={30} />
  </Filter>
);

const AsideStyledCard = styled(Card)({
  minWidth: "50%",
  marginLeft: "1em",
});

const Aside: React.FC = () => {
  const { data } = useListContext();
  const chartData = data
    ? data.map((record: any) => ({
        total: record.total,
        published: record.published,
        offShelf: record.offShelf,
        timeOut: record.timeOut,
        abnormal: record.abnormal,
        avatar: record.avatar,
        name: record.name,
      }))
    : [];

  return (
    <AsideStyledCard>
      <CardContent>
        {chartData.length === 0 ? (
          <Typography variant="h6" align="center">
            无数据
          </Typography>
        ) : (
          <BarChart
            series={[
              {
                data: chartData.map((item) => item.total),
                label: "总数",
                stack: "B",
              },
              {
                data: chartData.map((item) => item.published),
                label: "已发布",
                stack: "A",
              },
              {
                data: chartData.map((item) => item.offShelf),
                label: "已下架",
                stack: "A",
              },
              {
                data: chartData.map((item) => item.timeOut),
                label: "已过期",
                stack: "A",
              },
              {
                data: chartData.map((item) => item.abnormal),
                label: "异常",
              },
            ]}
            height={290}
            xAxis={[
              {
                data: chartData.map((item) => item.name),
                scaleType: "band",
              },
            ]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        )}
      </CardContent>
    </AsideStyledCard>
  );
};

const UserCargoStats: React.FC = () => {
  const handleRowClick = (id: Identifier): string => {
    return `/user-cargo-stats/${id.toString()}`;
  };

  return (
    <List
      title="用户发物统计"
      resource="users/cargo-stats"
      filters={<UserCargoStatsFilter />}
      aside={<Aside />}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick={(id, basePath, record) => handleRowClick(record.id)}
      >
        <FunctionField
          render={(record: User) => <UserAvatarWithName record={record} />}
        />
        <TextField source="total" label="总数" />
        <TextField source="published" label="已发布" />
        <TextField source="offShelf" label="已下架" />
        <TextField source="timeOut" label="已过期" />
        <TextField source="abnormal" label="异常" />
      </Datagrid>
    </List>
  );
};

export default UserCargoStats;
