import jsonServerProvider from 'ra-data-json-server';
import { DataProvider, GetListParams, GetManyParams, GetManyReferenceParams } from 'react-admin';
import Keycloak from "keycloak-js";
import { createHttpClient } from '../utils/http-client';
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';


function buildUrl(base: string, paths: string[], params: Record<string, any>) {
    const path = paths.join('/');
    const query = stringify(params);
    return `${base}/${path}?${query}`;
}

interface gybGetListParams extends GetListParams {
    target?: string;
    targetId?: string;
}



export const dataProvider = (keycloak: Keycloak): DataProvider => {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8055';
    const baseDataProvider = jsonServerProvider(apiUrl, createHttpClient(keycloak));

    const myDataProvider: DataProvider = {
        ...baseDataProvider,
        getManyReference: async (resource: string, params: GetManyReferenceParams) => {
            const splitResource = resource.split('/');
            const parentPath = splitResource.length > 1 ? splitResource[0] : null;
            const childResource = splitResource.length > 1 ? splitResource[1] : resource;
            const { target, id } = params;
            if (resource && target) {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    ...fetchUtils.flattenObject(params.filter),
                    _sort: field,
                    _order: order,
                    _start: (page - 1) * perPage,
                    _end: page * perPage,
                };
                const url = parentPath
                    ? `${apiUrl}/${parentPath}/${id}/${childResource}?${stringify(query)}`
                    : `${apiUrl}/${childResource}?${stringify(query)}`;
                const { headers, json } = await createHttpClient(keycloak)(url);
                const totalCount = headers ? parseInt(headers.get('x-total-count')?.split('/').pop() || '0', 10) : 0;
                return {
                    data: json,
                    total: totalCount,
                };
            }
            return baseDataProvider.getManyReference(resource, params);
        },
        getOneWithSubResource: async (resource: string, id: string, subResource: string, subId: string) => {
            const url = `${apiUrl}/${resource}/${id}/${subResource}/${subId}`;
            const { json } = await createHttpClient(keycloak)(url);
            return { data: json };
        },
        getMany: async (resource: string, params: GetManyParams) => {
            const url = `${apiUrl}/${resource}/many?${stringify({ ids: params.ids })}`;
            const { json } = await createHttpClient(keycloak)(url);
            return { data: json };
        },
        getList: async (resource: string, params: gybGetListParams) => {
            const { target, targetId, ...otherFilters } = params.filter;
            const paths = [resource, target, targetId].filter(Boolean) as string[];
            const query = {
                ...fetchUtils.flattenObject(otherFilters),
                _sort: params.sort.field,
                _order: params.sort.order,
                _start: (params.pagination.page - 1) * params.pagination.perPage,
                _end: params.pagination.page * params.pagination.perPage,
            };
            const url = buildUrl(apiUrl, paths, query);
            const { headers, json } = await createHttpClient(keycloak)(url);
            const totalCount = headers ? parseInt(headers.get('x-total-count')?.split('/').pop() || '0', 10) : 0;
            return {
                data: json,
                total: totalCount,
            };
        },
        // 发送移动app系统推送消息
        sendPushNotification: async (params: any) => {
            const url = `${apiUrl}/push/mobile-system-push`;
            const { json } = await createHttpClient(keycloak)(url, {
                method: 'POST',
                body: JSON.stringify(params),
            });
            return { data: json };
        },
        sendSmsNotification: async (params: any) => {
            const url = `${apiUrl}/push/sms-push`;
            const { json } = await createHttpClient(keycloak)(url, {
                method: 'POST',
                body: JSON.stringify(params),
            });
            return { data: json };
        },
        fetchPushRecord: async (userId: string) => {
            const url = `${apiUrl}/push/push-record/${userId}`;
            const { json } = await createHttpClient(keycloak)(url);
            return { data: json };
        },
        fetchUserCargoStats: async (userId: string, days: number) => {
            const url = `${apiUrl}/users/cargo-stats/${userId}?days=${days}`;
            const { json } = await createHttpClient(keycloak)(url);
            return json;
        }

    };

    return myDataProvider;
};