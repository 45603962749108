import LocalShippingSharpIcon from '@mui/icons-material/LocalShippingSharp';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InventoryIcon from '@mui/icons-material/Inventory';
import SettingsIcon from "@mui/icons-material/Settings";
import { CircleNotifications, ExpandMore, Group, PhoneIphone } from '@mui/icons-material';
import { Route } from '@mui/icons-material';
import { Place } from '@mui/icons-material';
import { LocalShippingOutlined } from '@mui/icons-material';
import { AirlineSeatReclineNormalOutlined } from '@mui/icons-material';
import { PhoneAndroid } from '@mui/icons-material';
import { ListAlt } from '@mui/icons-material';
import { History } from '@mui/icons-material';
import { Sms } from '@mui/icons-material';
import { NoCrash } from '@mui/icons-material';
import { Analytics } from '@mui/icons-material';
import { ShoppingCart } from '@mui/icons-material';

// 导入其他图标...

const icons = {
    drivers: LocalShippingSharpIcon,
    cargos: InventoryIcon,
    orders: ListAltIcon,
    scheduling: CalendarMonthIcon,
    settingsIcon: SettingsIcon,
    group: Group,
    route: Route,
    station: Place,
    msDrivers: AirlineSeatReclineNormalOutlined,
    msTrucks: LocalShippingOutlined,
    android: PhoneAndroid,
    list: ListAlt,
    app: PhoneIphone,
    notification: CircleNotifications,
    history: History,
    expandMore: ExpandMore,
    sms: Sms,
    reserve: NoCrash,
    analytics: Analytics,
    shoppingCart: ShoppingCart,
    // 添加其他图标...
};

export default icons;