import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import {
  AppBar,
  Logout,
  UserMenu,
  useGetIdentity,
  useUserMenu,
  ToggleThemeButton,
  LoadingIndicator,
} from "react-admin";
import UserContext from "../../contexts/UserContext";
import icons from "../../styles/icons/icons";
import Clock from "./clock";

export const GybAppBar = () => {
  const { setBranch, setBranchId } = React.useContext(UserContext);
  const { identity } = useGetIdentity();

  React.useEffect(() => {
    if (identity) {
      setBranch(identity.branch);
      setBranchId(identity.branchId);
    }
  }, [identity, setBranch, setBranchId]);

  return (
    <AppBar
      toolbar={
        <>
          <Clock />
          <ToggleThemeButton />
          <LoadingIndicator />
        </>
      }
      userMenu={
        <UserMenu>
          <SettingsMenuItem />
          <Logout />
        </UserMenu>
      }
      color="primary"
      position="fixed"
    />
  );
};

const SettingsMenuItem = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const { branch } = React.useContext(UserContext);

  return (
    <MenuItem
      onClick={onClose}
      ref={ref as React.RefObject<HTMLLIElement>}
      {...props}
    >
      <ListItemIcon>
        <icons.group fontSize="small" />
      </ListItemIcon>
      <ListItemText>{branch}</ListItemText>
    </MenuItem>
  );
});
