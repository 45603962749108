import { Alert, Button } from "@mui/material";
import { useState } from "react";
import {
  AutocompleteArrayInput,
  Datagrid,
  Edit,
  ListContextProvider,
  Pagination,
  ReferenceArrayInput,
  ReferenceManyField,
  required,
  TabbedForm,
  TextField,
  TextInput,
  useDataProvider,
  useGetList,
  useList,
  useNotify,
  useRefresh,
} from "react-admin";
import { useParams } from "react-router-dom";
import { CreateDialog } from "../../components/create-dialog";
import { createStationFields, CustomField, SourceIcon } from "./common";
import { FleetGuideCard } from "./fleet-guide-card";

const createTrucksFilterQuery = (searchText: any) => ({
  truck: `%${searchText}%`,
});

const createStationsFilterQuery = (searchText: any) => ({
  name: `%${searchText}%`,
});

export const FleetEdit: React.FC = (props) => {
  const { id } = useParams();
  const [filter, setFilter] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const { data, total, isLoading } = useGetList(`fleets/${id}/assignment`, {
    pagination: { page: page, perPage: perPage },
    sort: { field: "id", order: "ASC" },
    filter: { driver: filter },
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const createStation = async (data: any) => {
    const { data: newStation } = await dataProvider.create("stations", {
      data: { name: data.stationName, location: data.location },
    });
    if (newStation) {
      notify("stationCreated", { type: "success" });
      handleDialogClose();
      refresh();
    } else {
      notify("stationCreateFailed", { type: "error" });
    }
  };

  const listContext = useList({ data, isLoading, perPage, page });
  return (
    <Edit {...props} aside={<FleetGuideCard />}>
      <TabbedForm>
        <TabbedForm.Tab label="司机与货车">
          <Alert severity="info">只有绑定了货车的司机才能加入车队</Alert>
          {data && Object.keys(data).length > 0 && (
            <TextInput
              source="driver.name"
              label="搜索司机姓名"
              onChange={(e) => setFilter(e.target.value)}
            />
          )}
          <ListContextProvider value={{ ...listContext, total: total || 0 }}>
            <Datagrid bulkActionButtons={false}>
              <TextField source="driver.name" label="名称" />
              <TextField source="driver.phone" label="手机" />
              <CustomField source="truck.licensePlate" label="车牌" />
              <CustomField source="truck.plateColor" label="车牌颜色" />
              <CustomField source="truck.truckType" label="车型" />
              <CustomField source="truck.truckLength" label="车长" />
              <SourceIcon label="渠道标记" />
            </Datagrid>
            <Pagination page={page} setPage={setPage} setPerPage={setPerPage} />
          </ListContextProvider>
          <ReferenceArrayInput
            source="fleetAssignments"
            reference="fleetAssignments"
          >
            <AutocompleteArrayInput
              fullWidth
              optionText={(option) =>
                `${option.truck.licensePlate ?? option.truck.plate} - ${
                  option.driver === null ? "未知司机" : option.driver.name
                } - ${option.source ?? `线下收集`}`
              }
              label="添加司机与货车（可输入车牌号快速查找）"
              filterToQuery={createTrucksFilterQuery}
              noOptionsText="无选项"
            />
          </ReferenceArrayInput>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="基本信息">
          <TextInput source="name" label="车队名称" validate={required()} />
          <TextInput source="description" label="车队描述" />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="站点">
          <ReferenceManyField
            label="站点"
            reference={`fleets/stations`}
            target="fleetId"
          >
            <Datagrid>
              <TextField source="name" label="站点名称" />
              <TextField source="location.province" label="省份" />
              <TextField source="location.city" label="城市" />
              <TextField source="location.district" label="区域" />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceArrayInput source="stations" reference="stations">
            <AutocompleteArrayInput
              fullWidth
              optionText="name"
              label="选择并添加站点（可搜索站点名称）"
              filterToQuery={createStationsFilterQuery}
              noOptionsText="无选项"
            />
          </ReferenceArrayInput>
          <Alert severity="info">
            没有找到合适的站点？
            <Button onClick={handleDialogOpen}>创建站点</Button>
          </Alert>

          <CreateDialog
            open={dialogOpen}
            onClose={handleDialogClose}
            mutationFn={createStation}
            title="创建站点"
            fields={createStationFields}
          />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};
