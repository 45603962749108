import * as React from "react";
import CargoContext from "./CargoContext";
import DriverContext from "./DriverContext";
import UserContext from "./UserContext";
import { ContextProviderProps } from "../models/common";
import { Cargo } from "../models/Cargo";

const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
  const [selectedCargo, setSelectedCargo] = React.useState<Cargo | null>(null);
  const [driverId, setDriverId] = React.useState<string | null>(null);
  const [branchId, setBranchId] = React.useState<string | null>(null);
  const [branch, setBranch] = React.useState<string | null>(null);
  const [userId, setUserId] = React.useState<string | null>(null);

  return (
    <UserContext.Provider
      value={{ userId, setUserId, branch, setBranch, branchId, setBranchId }}
    >
      <DriverContext.Provider value={{ driverId, setDriverId }}>
        <CargoContext.Provider value={{ selectedCargo, setSelectedCargo }}>
          {children}
        </CargoContext.Provider>
      </DriverContext.Provider>
    </UserContext.Provider>
  );
};

export default ContextProvider;
