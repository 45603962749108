import { Box, Button, Tooltip } from "@mui/material";
import React from "react";
import {
  Datagrid,
  FunctionField,
  InfiniteList,
  TextField as RaTextField,
  useInfinitePaginationContext,
} from "react-admin";
import { Cargo } from "../../../models/Cargo";
import { renderRating } from "../common";
import PushRecord from "../push-record";
import { Actions, SchedulingBulkActionButtons } from "./actions";
import { SchedulingFilter } from "./scheduling-filter";
import { renderRecord } from "./utils";

const LoadMore = () => {
  const { hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfinitePaginationContext();
  return hasNextPage ? (
    <Box mt={1} textAlign="center">
      <Button disabled={isFetchingNextPage} onClick={() => fetchNextPage()}>
        更多
      </Button>
    </Box>
  ) : null;
};

export const DriverSchedulingList = React.memo(
  ({ selectedCargo }: { selectedCargo: Cargo }) => {
    const renderRatingWithCargo = (record: any) => (
      <Tooltip
        title={
          <div>
            - 距离较近：增加1颗星
            <br />
            - 订单匹配：大于5个订单增加2.5颗星，否则增加1.5颗星
            <br />
            - 专线匹配：增加1.5颗星
            <br />
            - 货物无车长和车型要求：增加1颗星
            <br />
            - 车型与车长都匹配：增加1.5颗星
            <br />- 只有车型或只有车长匹配：增加0.5颗星
          </div>
        }
        placement="top"
      >
        <span>{renderRating(record, selectedCargo)}</span>
      </Tooltip>
    );
    return (
      <InfiniteList
        resource="schedulings"
        filter={{ cargoId: selectedCargo?.id }}
        perPage={10}
        sort={{ field: "id", order: "ASC" }}
        pagination={<LoadMore />}
        filters={<SchedulingFilter />}
        actions={<Actions />}
      >
        <Datagrid bulkActionButtons={<SchedulingBulkActionButtons />}>
          <RaTextField source="name" label="姓名" />
          <RaTextField source="phone" label="手机号" />
          <RaTextField source="trucks.0.plate" label="车牌" />
          <RaTextField source="trucks.0.type" label="车型" />
          <FunctionField label="备注" render={renderRecord} />
          <FunctionField
            label="推送记录"
            render={(record: any) => <PushRecord userId={record.id} />}
          />
          <FunctionField label="推荐星级" render={renderRatingWithCargo} />
        </Datagrid>
      </InfiniteList>
    );
  }
);
