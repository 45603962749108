import * as React from "react";
import { Admin, CustomRoutes, Resource, defaultTheme } from "react-admin";
import { dataProvider } from "./providers/dataProvider";
import ContextProvider from "./contexts/ContextProvider";
import { PaletteMode } from "@mui/material";
import { keycloakAuthProvider } from "./providers/authProvider";
import Keycloak from "keycloak-js";
import { kcConfig, kcInitOptions } from "./utils/keycloak";
import { Loading } from "react-admin";
import { resources } from "./resources/resouces";
import { GybLayout } from "./layouts/gyb-layout";
import { Route } from "react-router-dom";
import { customRoutes } from "./routers/routers";
import { i18nProvider } from "./providers/i18nProvider";

const lightTheme = defaultTheme;
const darkTheme = { ...defaultTheme, palette: { mode: "dark" as PaletteMode } };

const App: React.FC = () => {
  const [keycloak, setKeycloak] = React.useState<Keycloak | null>(null);
  const initializing = React.useRef(false);

  React.useEffect(() => {
    if (!keycloak && !initializing.current) {
      initializing.current = true;
      const keycloakClient = new Keycloak(kcConfig);
      keycloakClient
        .init(kcInitOptions)
        .then(() => {
          setKeycloak(keycloakClient);
          initializing.current = false;
        })
        .catch((error) => {
          console.error("Failed to initialize Keycloak", error);
          initializing.current = false;
        });
    }
  }, [keycloak]);

  React.useEffect(() => {
    if (keycloak) {
      const refreshTokenInterval = setInterval(() => {
        keycloak
          .updateToken(30) // 刷新Token，如果Token在30秒内过期
          .then((refreshed) => {
            if (refreshed) {
              console.log("Token was successfully refreshed");
            } else {
              console.warn("Token is still valid");
            }
          })
          .catch(() => {
            console.error(
              "Failed to refresh the token, or the session has expired"
            );
          });
      }, 60000); // 每分钟检查一次Token

      return () => clearInterval(refreshTokenInterval); // 清除定时器
    }
  }, [keycloak]);

  if (!keycloak) return <Loading />;

  return (
    <ContextProvider>
      <Admin
        layout={GybLayout}
        authProvider={keycloakAuthProvider(keycloak)}
        dataProvider={dataProvider(keycloak)}
        i18nProvider={i18nProvider}
        theme={lightTheme}
        darkTheme={darkTheme}
      >
        {resources.map((resource) => (
          <Resource key={resource.name} {...resource} />
        ))}
        <CustomRoutes>
          {customRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </CustomRoutes>
      </Admin>
    </ContextProvider>
  );
};

export default App;
