import { Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent/CardContent";
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Show,
  SimpleShowLayout,
  FunctionField,
  ListProps,
  EditProps,
  CreateProps,
  ShowProps,
  DateField,
  FilterLiveSearch,
  SavedQueriesList,
  useShowController,
} from "react-admin";
import { Cargo } from "../models/Cargo";
import { CargoCard } from "./components/cargo-card";
import { AsideStyledCard, CargoNameWithImageField } from "./components/common";

// 创建一个通用的函数来转换布尔值为 "是" 或 "否"
const BooleanField: React.FC<{ source: string; label: string }> = ({
  source,
  label,
}) => (
  <FunctionField
    source={source}
    label={label}
    render={(record: Cargo) => (record[source] ? "是" : "否")}
  />
);

const Aside = () => (
  <AsideStyledCard>
    <CardContent>
      <FilterLiveSearch source="name" label="货物名" alwaysOn />
      <SavedQueriesList />
    </CardContent>
  </AsideStyledCard>
);

export const CargoList: React.FC<ListProps> = (props) => (
  <List {...props} aside={<Aside />}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" label="货号" />
      <FunctionField
        label="货物名称"
        sortBy="cargo.name"
        render={(record: Cargo) => <CargoNameWithImageField cargo={record} />}
      />
      <FunctionField
        label="发→收"
        render={(record: Cargo) => `${record.from.city}→ ${record.to.city}`}
      />
      <TextField source="unit_price" label="货物单价" />
      <DateField
        source="expect_departure_at"
        label="装车时间"
        showTime
        locales="zh-CN"
      />
      <TextField source="deposit" label="押金" />
      <BooleanField source="deposit_refund" label="是否退还押金" />
      <BooleanField source="continuous" label="是否是长期货源" />
      <BooleanField source="single_price" label="是否一口价" />
    </Datagrid>
  </List>
);

export const CargoEdit: React.FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" label="货物名" />
      <TextInput source="description" label="货物描述" />
      <TextInput source="unit_price" label="货物单价" />
    </SimpleForm>
  </Edit>
);

export const CargoCreate: React.FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="货物名" />
      <TextInput source="description" label="货物描述" />
      <TextInput source="unit_price" label="货物单价" />
    </SimpleForm>
  </Create>
);

export const CargoShow: React.FC<ShowProps> = (props) => {
  const { record } = useShowController(props);
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <CargoCard cargo={record} />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};
