import { Tooltip, useTheme } from "@mui/material";
import { Loading, useDataProvider } from "react-admin";
import { useQuery } from "react-query";
import icons from "../../styles/icons/icons";

const PushRecord = ({
  userId,
  showAppPushLength = true,
}: {
  userId: string;
  showAppPushLength?: boolean;
}) => {
  const dataProvider = useDataProvider();
  const theme = useTheme();
  const { data, isLoading, error } = useQuery(["pushRecord", userId], () =>
    dataProvider.fetchPushRecord(userId)
  );

  if (isLoading) return <Loading />;
  if (error) {
    return <p>ERROR</p>;
  }
  if (!data) return <p>无数据</p>;
  return (
    <div>
      {showAppPushLength && (
        <>
          <Tooltip title="App推送数量">
            <icons.notification style={{ color: theme.palette.grey[500] }} />
          </Tooltip>
          <span style={{ color: theme.palette.grey[600] }}>
            {data.data.appPushLength}
          </span>
        </>
      )}
      <Tooltip title="短信推送数量">
        <icons.sms style={{ color: theme.palette.grey[500] }} />
      </Tooltip>
      <span style={{ color: theme.palette.grey[600] }}>
        {data.data.smsPushLength}
      </span>
    </div>
  );
};

export default PushRecord;
