import { KeycloakInitOptions } from 'keycloak-js';

const { REACT_APP_AUTH_URL, REACT_APP_AUTH_REALM, REACT_APP_AUTH_CLIENT_ID } = process.env;

if (!REACT_APP_AUTH_URL || !REACT_APP_AUTH_REALM || !REACT_APP_AUTH_CLIENT_ID) {
    throw new Error('您没有配置环境变量');
}

export const kcConfig = {
    url: REACT_APP_AUTH_URL,
    realm: REACT_APP_AUTH_REALM,
    clientId: REACT_APP_AUTH_CLIENT_ID,
};

export const kcInitOptions: KeycloakInitOptions = { onLoad: 'login-required', checkLoginIframe: false };