import { BooleanInput, Filter } from "react-admin";

export const SchedulingFilter = (props: any) => (
  <Filter {...props}>
    <BooleanInput
      source="nearest"
      label="距离最近"
      defaultValue={false}
      alwaysOn
    />
    <BooleanInput
      source="lineMatch"
      label="专线匹配"
      defaultValue={false}
      alwaysOn
    />
    <BooleanInput
      source="orderMatch"
      label="运单匹配"
      defaultValue={false}
      alwaysOn
    />
  </Filter>
);
