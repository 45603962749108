import icons from "../styles/icons/icons";
import { CargoList, CargoShow } from "../views/cargoes";
import { DriverEdit, DriverList, DriverShow } from "../views/drivers";
import { FleetList } from "../views/fleets/fleets/fleet-list";
import { StationCreate, StationEdit, StationList, StationShow } from "../views/fleets/stations";
import { MSTruckCreate, MSTruckEdit, MSTruckList, MSTruckShow } from "../views/fleets/ms-trucks";
import { Scheduling } from "../views/scheduling";
import { WaybillList, WaybillShow } from "../views/waybills";
import { MSDriverCreate, MSDriverEdit, MSDriverList, MSDriverShow } from "../views/fleets/ms-drivers";
import { FleetCreate } from "../views/fleets/fleets/fleet-create";
import { FleetShow } from "../views/fleets/fleets/fleet-show";
import { FleetEdit } from "../views/fleets/fleets/fleet-edit";
import { PushHistoryList, PushHistoryShow } from "../views/push-history";
import { ReserveCapacityCreate, ReserveCapacityEdit, ReserveCapacityList, ReserveCapacityShow } from "../views/reserve-capacity";

export const resources = [
  {
    name: "drivers",
    icon: icons.drivers,
    options: { label: "App司机" },
    list: DriverList,
    show: DriverShow,
    edit: DriverEdit,
  },
  {
    name: "waybills",
    icon: icons.orders,
    options: { label: "App运单" },
    list: WaybillList,
    show: WaybillShow,
  },
  {
    name: "validCargos",
    icon: icons.cargos,
    options: { label: "App货物" },
    list: CargoList,
    show: CargoShow,
  },
  {
    name: "schedulings",
    icon: icons.scheduling,
    options: { label: "调度" },
    list: Scheduling,
    show: DriverShow,
  },
  {
    name: "fleets",
    icon: icons.route,
    options: { label: "车队" },
    list: FleetList,
    create: FleetCreate,
    show: FleetShow,
    edit: FleetEdit,
  },
  {
    name: "stations",
    icon: icons.station,
    options: { label: "车队站点" },
    list: StationList,
    create: StationCreate,
    show: StationShow,
    edit: StationEdit,
  },
  {
    name: "msDrivers",
    icon: icons.msDrivers,
    options: { label: "多渠道司机" },
    list: MSDriverList,
    create: MSDriverCreate,
    show: MSDriverShow,
    edit: MSDriverEdit,
  },
  {
    name: "msTrucks",
    icon: icons.msTrucks,
    options: { label: "多渠道货车" },
    list: MSTruckList,
    create: MSTruckCreate,
    show: MSTruckShow,
    edit: MSTruckEdit,
  },
  {
    name: "push",
    options: { label: "推送记录" },
    list: PushHistoryList,
    show: PushHistoryShow,
  },
  {
    name: "reserveCapacity",
    options: { label: "储备运力" },
    list: ReserveCapacityList,
    show: ReserveCapacityShow,
    edit: ReserveCapacityEdit,
    create: ReserveCapacityCreate

  }
];