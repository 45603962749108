import { TopToolbar, ExportButton } from "react-admin";
import { PushDialog } from "./push-dialog";

export const Actions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);

export const SchedulingBulkActionButtons = (props: any) => (
  <PushDialog in {...props} />
);
