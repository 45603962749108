import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';
import { AuthProvider } from 'react-admin';
import { jwtDecode } from "jwt-decode";

export type PermissionsFunction = (decoded: KeycloakTokenParsed) => any;

export const keycloakAuthProvider = (
    client: Keycloak,
    options: {
        onPermissions?: PermissionsFunction;
        loginRedirectUri?: string;
        logoutRedirectUri?: string;
    } = {}
): AuthProvider => ({
    async login() {
        return client.login({
            redirectUri: options.loginRedirectUri ?? window.location.origin,
        });
    },
    async logout() {
        return client.logout({
            redirectUri: options.logoutRedirectUri ?? window.location.origin,
        });
    },
    async checkError() {
        return Promise.resolve();
    },
    async checkAuth() {
        return client.authenticated && client.token
            ? Promise.resolve()
            : Promise.reject('Failed to obtain access token.');
    },
    async getPermissions() {
        if (!client.token) {
            return Promise.resolve(false);
        }
        const decoded = jwtDecode<KeycloakTokenParsed>(client.token);
        return Promise.resolve(
            options.onPermissions ? options.onPermissions(decoded) : decoded
        );
    },
    async getIdentity() {
        if (client.token) {
            const decoded = jwtDecode<KeycloakTokenParsed>(client.token);
            const id = decoded.sub || '';
            const fullName = decoded.name || '';
            const branch = decoded.branch || '';
            const branchId = decoded.branchId || '';
            return Promise.resolve({ id, fullName, branch, branchId });
        }
        return Promise.reject('Failed to get identity.');
    },
});