import {} from "react-admin";
import { Divider, Grid, Typography } from "@mui/material";
import { CargoList } from "./components/cargo-list";
import { DriverSchedulingList } from "./components/drivers-scheduling/driver-scheduling-list";
import CargoContext from "../contexts/CargoContext";
import React from "react";
import { FleetMatchingDrivers } from "./fleets/fleets/fleet-matching-drivers";

export const Scheduling = () => {
  const { selectedCargo: rawSelectedCargo } = React.useContext(CargoContext);
  const selectedCargo = React.useMemo(
    () => rawSelectedCargo,
    [rawSelectedCargo]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Typography>有效货物</Typography>
        <CargoList />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography>匹配司机（仅App）</Typography>
        {selectedCargo && (
          <DriverSchedulingList selectedCargo={selectedCargo} />
        )}
        <Divider style={{ margin: "20px 0" }} />
        <Typography>匹配车队司机（多渠道）</Typography>
        {selectedCargo && (
          <FleetMatchingDrivers selectedCargo={selectedCargo} />
        )}
      </Grid>
    </Grid>
  );
};
