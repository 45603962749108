import { useState } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';

export const useStationAndTagDialogs = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const [stationDialogOpen, setStationDialogOpen] = useState(false);
  const handleStationDialogOpen = () => setStationDialogOpen(true);
  const handleStationDialogClose = () => setStationDialogOpen(false);

  const [tagDialogOpen, setTagDialogOpen] = useState(false);
  const handleTagDialogOpen = () => setTagDialogOpen(true);
  const handleTagDialogClose = () => setTagDialogOpen(false);

  const createStation = async (data: any) => {
    try {
      const { data: newStation } = await dataProvider.create("stations", {
        data: { name: data.stationName, location: data.location },
      });
      if (newStation) {
        notify("stationCreated", { type: "success" });
        handleStationDialogClose();
        refresh();
      }
    } catch (error) {
      notify("stationCreateFailed", { type: "error" });
    }
  };

  const createTag = async (data: any) => {
    try {
      const { data: newTag } = await dataProvider.create("tags", {
        data: { name: data.tagName, category: "车型" },
      });
      if (newTag) {
        notify("tagCreated", { type: "success" });
        handleTagDialogClose();
        refresh();
      }
    } catch (error) {
      notify("tagCreateFailed", { type: "error" });
    }
  };

  return {
    stationDialogOpen,
    handleStationDialogOpen,
    handleStationDialogClose,
    tagDialogOpen,
    handleTagDialogOpen,
    handleTagDialogClose,
    createStation,
    createTag,
  };
};