import Keycloak from "keycloak-js";
import { fetchUtils } from "react-admin";
export const createHttpClient = (keycloak: Keycloak) => {
  return async (url: string, options: any = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = keycloak.token;
    options.headers.set('Authorization', `Bearer ${token}`);
    const response = await fetchUtils.fetchJson(url, options);
    if (response.status === 401 || response.status === 403) {
    }
    return response;
  };
};