import { CardContent } from "@mui/material";
import {
  AutocompleteArrayInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FilterLiveSearch,
  List,
  ReferenceArrayInput,
  ReferenceManyField,
  SavedQueriesList,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  useUnique,
} from "react-admin";
import { SourceType } from "../../models/common";
import { TruckLength, TruckPlateColor, TruckType } from "../../models/Truck";
import { AsideStyledCard } from "../components/common";
import { SmartSelect } from "../components/smart-select";

const fieldLabels = {
  licensePlate: "车牌",
  plateColor: "车牌颜色",
  truckType: "车型",
  truckLength: "车长",
};

function toChoices(obj: Record<string, any>) {
  return Object.entries(obj).map(([key, value]) => ({
    id: key,
    name: value,
  }));
}

export const MSTruckCreate = (props: any) => {
  const unique = useUnique();
  const transformData = (data: any) => ({
    ...data,
    source: SourceType.OfflineCollection,
  });

  return (
    <Create {...props} transform={transformData}>
      <SimpleForm>
        <TextInput
          source="licensePlate"
          label={fieldLabels.licensePlate}
          validate={unique({
            message: "车牌已存在",
          })}
        />
        <SmartSelect
          source="plateColor"
          label={fieldLabels.plateColor}
          choices={toChoices(TruckPlateColor)}
          optionText="name"
          optionValue="name"
        />
        <SmartSelect
          source="truckType"
          label={fieldLabels.truckType}
          choices={toChoices(TruckType)}
          optionText="name"
          optionValue="name"
        />
        <SmartSelect
          source="truckLength"
          label={fieldLabels.truckLength}
          choices={toChoices(TruckLength)}
          optionText="name"
          optionValue="name"
        />
      </SimpleForm>
    </Create>
  );
};

const Aside = () => (
  <AsideStyledCard>
    <CardContent>
      <FilterLiveSearch source="licensePlate" label="车牌" alwaysOn />
      <FilterLiveSearch source="plateColor" label="车牌颜色" alwaysOn />
      <FilterLiveSearch source="truckType" label="车型" alwaysOn />
      <FilterLiveSearch source="truckLength" label="车长" alwaysOn />
      <SavedQueriesList />
    </CardContent>
  </AsideStyledCard>
);

export const MSTruckList = (props: any) => (
  <List {...props} aside={<Aside />}>
    <Datagrid>
      <TextField source="licensePlate" label={fieldLabels.licensePlate} />
      <TextField source="plateColor" label={fieldLabels.plateColor} />
      <TextField source="truckType" label={fieldLabels.truckType} />
      <TextField source="truckLength" label={fieldLabels.truckLength} />
      <ReferenceManyField
        label="司机"
        reference={`msTrucks/drivers`}
        target="truckId"
      >
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <EditButton />
    </Datagrid>
  </List>
);

const filterToQuery = (searchText: any) => ({
  name: `%${searchText}%`,
});

export const MSTruckEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="licensePlate" label={fieldLabels.licensePlate} />
      <SmartSelect
        source="plateColor"
        label={fieldLabels.plateColor}
        choices={toChoices(TruckPlateColor)}
        optionText="name"
        optionValue="name"
      />
      <SmartSelect
        source="truckType"
        label={fieldLabels.truckType}
        choices={toChoices(TruckType)}
        optionText="name"
        optionValue="name"
      />
      <SmartSelect
        source="truckLength"
        label={fieldLabels.truckLength}
        choices={toChoices(TruckLength)}
        optionText="name"
        optionValue="name"
      />
      <ReferenceManyField
        label="司机"
        reference={`msTrucks/drivers`}
        target="truckId"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" label="姓名" />
          <TextField source="phone" label="手机" />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceArrayInput source="drivers" reference="msDrivers">
        <AutocompleteArrayInput
          fullWidth
          optionText="name"
          label="添加司机"
          filterToQuery={filterToQuery}
          noOptionsText="无选项"
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const MSTruckShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="licensePlate" label={fieldLabels.licensePlate} />
      <TextField source="plateColor" label={fieldLabels.plateColor} />
      <TextField source="truckType" label={fieldLabels.truckType} />
      <TextField source="truckLength" label={fieldLabels.truckLength} />
    </SimpleShowLayout>
  </Show>
);
