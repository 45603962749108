import { Avatar, Box, Card, Rating, styled, Typography } from "@mui/material";
import { FunctionField } from "react-admin";
import { Cargo } from "../../models/Cargo";
import { User } from "../../models/common";

export const TruncatedTextField: React.FC<{
  source: string;
  maxLength?: number;
}> = ({ source, maxLength = 20 }) => (
  <FunctionField
    source={source}
    render={(record: any) => {
      const value = record[source];
      return value && value.length > maxLength
        ? `${value.substring(0, maxLength)}...`
        : value;
    }}
  />
);

export const AsideStyledCard = styled(Card)({
  minWidth: "15em",
  marginLeft: "1em",
});

export const UserAvatarWithName = ({ record }: { record: User }) => {
  if (!record) return null;
  return (
    <Box display="flex" alignItems="center">
      <Avatar src={record.avatar} alt={record.name} />
      <Typography variant="body1" style={{ marginLeft: 8 }}>
        {record.name}
      </Typography>
    </Box>
  );
};

export const CargoNameWithImageField = ({ cargo }: { cargo: Cargo }) => {
  return (
    <Box display="flex" alignItems="center">
      <img
        src={cargo.image}
        alt={cargo.name}
        style={{
          width: "96px",
          height: "96px",
          marginRight: "8px",
          borderRadius: "8px",
        }}
      />
      <Typography>{cargo.name}</Typography>
    </Box>
  );
};

export const renderRating = (record: any, cargo: Cargo) => {
  let value = 0;
  let isNear =
    record.distance && record.positions && record.positions.length > 0;
  if (isNear) {
    value += 1;
  }
  if (record.matchedOrders) {
    const orderCount = record.matchedOrders;
    value += orderCount > 5 ? 2.5 : 1.5;
  }
  if (!isNear && !record.matchedOrders) {
    value += 1.5;
  }

  const hasLengthRequirements =
    cargo.truck_lengths && cargo.truck_lengths.length > 0;
  const hasTypeRequirements = cargo.truck_types && cargo.truck_types.length > 0;

  if (!hasLengthRequirements && !hasTypeRequirements) {
    value += 1;
  } else {
    const lengthMatch = record.trucks.some((truck: any) =>
      cargo.truck_lengths.includes(truck.truck_length)
    );
    const typeMatch = record.trucks.some((truck: any) =>
      cargo.truck_types.includes(truck.truck_type)
    );

    if (typeMatch && lengthMatch) {
      value += 1.5;
    } else if (typeMatch || lengthMatch) {
      value += 0.5;
    }
  }
  return <Rating name="read-only" value={value} readOnly precision={0.5} />;
};
