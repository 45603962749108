export function TruckType(type: number | null | undefined): string {
    if (type === null || type === undefined) {
      return "未知车型";
    }
    switch (type) {
      case 0:
        return "平板";
      case 1:
        return "高栏";
      case 2:
        return "厢式";
      case 3:
        return "自卸";
      case 4:
        return "冷藏";
      case 5:
        return "爬梯";
      case 6:
        return "集装箱";
      case 7:
        return "高低板";
      default:
        return "未知车型";
    }
  }
  
  export function TruckLength(length: number | null | undefined): string {
    if (length === null || length === undefined) {
      return "未知车长";
    }
    switch (length) {
      case 0:
        return "4.2m";
      case 1:
        return "6.8m";
      case 2:
        return "9.6m";
      case 3:
        return "13m";
      case 4:
        return "17.5m";
      case 5:
        return "8.7m";
      case 6:
        return "13.75m";
      default:
        return "未知车长";
    }
  }
  
  export function PlateColor(color: number | null | undefined): string {
    if (color === null || color === undefined) {
      return "未知颜色";
    }
    switch (color) {
      case 0:
        return "蓝色";
      case 1:
        return "黄色";
      case 2:
        return "黑色";
      case 3:
        return "白色";
      case 4:
        return "绿色";
      default:
        return "未知颜色";
    }
  }