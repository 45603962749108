import { Alert, Box, Button, CardContent, Typography } from "@mui/material";
import React, { useContext } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  TextInput,
  Edit,
  Create,
  Show,
  SimpleShowLayout,
  FilterLiveSearch,
  SavedQueriesList,
  ReferenceManyField,
  Labeled,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SingleFieldList,
  ChipField,
  ArrayField,
  FunctionField,
} from "react-admin";
import CargoContext from "../contexts/CargoContext";
import { Cargo } from "../models/Cargo";
import { CreateDialog } from "./components/create-dialog";
import { SchedulingBulkActionButtons } from "./components/drivers-scheduling/actions";
import PushRecord from "./components/push-record";
import { SmallCargoCard } from "./components/small-cargo-card";
import { SwitchCargo } from "./components/switch-cargo";
import { useStationAndTagDialogs } from "./components/use-station-and-tag-dialog";
import { createStationFields, createTagFields } from "./fleets/fleets/common";
import { CargoMatchFilters } from "./reserve-capacity/cargo-match-filters";

const fieldLabels = {
  licensePlate: "车牌",
  vehicleType: "车辆类型",
  vehicleCount: "车辆数量",
  phone: "电话",
  origin: "归属地",
  routes: "路线",
  remarks: "备注",
  name: "名称",
  wechat: "微信",
  createdAt: "创建时间",
  updatedAt: "更新时间",
};

const Aside = ({ cargo }: { cargo: Cargo | null }) => {
  const shouldDisplayCargoCard = cargo && Object.keys(cargo).length > 0;
  return (
    <Box sx={{ width: 400, margin: 4 }}>
      <SwitchCargo />
      <Alert severity="info">储备运力匹配需在选定货源后方可启用。</Alert>
      {shouldDisplayCargoCard && (
        <div>
          <Typography
            variant="body2"
            style={{ marginLeft: "8px", marginBottom: "10px" }}
          >
            已选中货源
          </Typography>
          <SmallCargoCard {...cargo} />
        </div>
      )}
      <CardContent>
        <FilterLiveSearch source="vehicleType" label="车辆类型" alwaysOn />
        <FilterLiveSearch source="phone" label="电话" alwaysOn />
        <FilterLiveSearch source="routes" label="路线" alwaysOn />
        <FilterLiveSearch source="remarks" label="备注" alwaysOn />
        <SavedQueriesList />
      </CardContent>
    </Box>
  );
};

export const ReserveCapacityList: React.FC = (props) => {
  const { selectedCargo } = useContext(CargoContext);
  const availableStrategies = ["sms"];
  return (
    <List
      {...props}
      bulkActionButtons={
        selectedCargo ? (
          <SchedulingBulkActionButtons
            initialPushStrategy="sms"
            availableStrategies={availableStrategies}
          />
        ) : undefined
      }
      filter={selectedCargo ? { cargoId: selectedCargo?.id } : undefined}
      filters={selectedCargo ? <CargoMatchFilters /> : undefined}
      aside={<Aside cargo={selectedCargo as Cargo} />}
    >
      <Datagrid rowClick="show">
        <TextField source="vehicleType" label={fieldLabels.vehicleType} />
        <TextField source="vehicleCount" label={fieldLabels.vehicleCount} />
        <TextField source="phone" label={fieldLabels.phone} />
        {/* <TextField source="origin" label={fieldLabels.origin} /> */}
        <FunctionField
          label="推送记录"
          render={(record: any) => (
            <PushRecord userId={record.id} showAppPushLength={false} />
          )}
        />
        <TextField source="routes" label={fieldLabels.routes} />
        <TextField source="remarks" label={fieldLabels.remarks} />
        <TextField source="name" label={fieldLabels.name} />
        <TextField source="wechat" label={fieldLabels.wechat} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const createFilterQuery = (searchText: any) => ({
  name: `%${searchText}%`,
});

export const ReserveCapacityEdit: React.FC = (props) => {
  const {
    stationDialogOpen,
    handleStationDialogOpen,
    handleStationDialogClose,
    tagDialogOpen,
    handleTagDialogOpen,
    handleTagDialogClose,
    createStation,
    createTag,
  } = useStationAndTagDialogs();

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="vehicleType" label={fieldLabels.vehicleType} />
        <ReferenceArrayInput source="tags" reference="tags" label="车型标签">
          <AutocompleteArrayInput
            fullWidth
            optionText="name"
            label="选择并添加标签（可搜索标签名称）"
            filterToQuery={createFilterQuery}
            noOptionsText="无选项"
          />
        </ReferenceArrayInput>
        <Alert severity="info">
          没有找到合适的标签？
          <Button onClick={handleTagDialogOpen}>创建标签</Button>
        </Alert>
        <CreateDialog
          open={tagDialogOpen}
          onClose={handleTagDialogClose}
          mutationFn={createTag}
          title="创建标签"
          fields={createTagFields}
        />
        <TextInput source="vehicleCount" label={fieldLabels.vehicleCount} />
        <TextInput source="phone" label={fieldLabels.phone} />
        <TextInput source="origin" label={fieldLabels.origin} />
        <TextInput source="routes" label={fieldLabels.routes} />
        <Labeled label="站点">
          <ReferenceManyField
            label="站点"
            reference={`reserveCapacity/stations`}
            target="id"
          >
            <Datagrid>
              <TextField source="name" label="站点名称" />
              <TextField source="location.province" label="省份" />
              <TextField source="location.city" label="城市" />
              <TextField source="location.district" label="区域" />
            </Datagrid>
          </ReferenceManyField>
        </Labeled>
        <ReferenceArrayInput source="stations" reference="stations">
          <AutocompleteArrayInput
            fullWidth
            optionText="name"
            label="选择并添加站点（可搜索站点名称）"
            filterToQuery={createFilterQuery}
            noOptionsText="无选项"
          />
        </ReferenceArrayInput>
        <Alert severity="info">
          没有找到合适的站点？
          <Button onClick={handleStationDialogOpen}>创建站点</Button>
        </Alert>

        <CreateDialog
          open={stationDialogOpen}
          onClose={handleStationDialogClose}
          mutationFn={createStation}
          title="创建站点"
          fields={createStationFields}
        />
        <TextInput fullWidth source="remarks" label={fieldLabels.remarks} />
        <TextInput source="name" label={fieldLabels.name} />
        <TextInput source="wechat" label={fieldLabels.wechat} />
      </SimpleForm>
    </Edit>
  );
};

export const ReserveCapacityCreate: React.FC = (props) => {
  const {
    stationDialogOpen,
    handleStationDialogOpen,
    handleStationDialogClose,
    tagDialogOpen,
    handleTagDialogOpen,
    handleTagDialogClose,
    createStation,
    createTag,
  } = useStationAndTagDialogs();

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="vehicleType" label={fieldLabels.vehicleType} />
        <ReferenceArrayInput source="tags" reference="tags" label="车型标签">
          <AutocompleteArrayInput
            fullWidth
            optionText="name"
            label="选择并添加标签（可搜索标签名称）"
            filterToQuery={createFilterQuery}
            noOptionsText="无选项"
          />
        </ReferenceArrayInput>
        <Alert severity="info">
          没有找到合适的标签？
          <Button onClick={handleTagDialogOpen}>创建标签</Button>
        </Alert>
        <CreateDialog
          open={tagDialogOpen}
          onClose={handleTagDialogClose}
          mutationFn={createTag}
          title="创建标签"
          fields={createTagFields}
        />
        <TextInput source="vehicleCount" label={fieldLabels.vehicleCount} />
        <TextInput source="phone" label={fieldLabels.phone} />
        <TextInput source="origin" label={fieldLabels.origin} />
        <TextInput source="routes" label={fieldLabels.routes} />
        <ReferenceArrayInput
          source="stations"
          reference="stations"
          label="站点"
        >
          <AutocompleteArrayInput
            fullWidth
            optionText="name"
            label="选择并添加站点（可搜索站点名称）"
            filterToQuery={createFilterQuery}
            noOptionsText="无选项"
          />
        </ReferenceArrayInput>
        <Alert severity="info">
          没有找到合适的站点？
          <Button onClick={handleStationDialogOpen}>创建站点</Button>
        </Alert>
        <CreateDialog
          open={stationDialogOpen}
          onClose={handleStationDialogClose}
          mutationFn={createStation}
          title="创建站点"
          fields={createStationFields}
        />
        <TextInput fullWidth source="remarks" label={fieldLabels.remarks} />
        <TextInput source="name" label={fieldLabels.name} />
        <TextInput source="wechat" label={fieldLabels.wechat} />
      </SimpleForm>
    </Create>
  );
};

export const ReserveCapacityShow: React.FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="vehicleType" label={fieldLabels.vehicleType} />
      <ArrayField source="licensePlate" label="车牌号码">
        <FunctionField
          render={(record: any) =>
            record.licensePlate && record.licensePlate.length > 0
              ? record.licensePlate.join(", ")
              : ""
          }
        />
      </ArrayField>
      <ReferenceManyField
        label="车型标签"
        reference={`reserveCapacity/tags`}
        target="id"
      >
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="vehicleCount" label={fieldLabels.vehicleCount} />
      <TextField source="phone" label={fieldLabels.phone} />
      <TextField source="origin" label={fieldLabels.origin} />
      <TextField source="routes" label={fieldLabels.routes} />
      <ReferenceManyField
        label="站点"
        reference={`reserveCapacity/stations`}
        target="id"
      >
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="remarks" label={fieldLabels.remarks} />
      <TextField source="name" label={fieldLabels.name} />
      <TextField source="wechat" label={fieldLabels.wechat} />
    </SimpleShowLayout>
  </Show>
);
