import CardContent from "@mui/material/CardContent/CardContent";
import {
  Datagrid,
  FilterLiveSearch,
  FunctionField,
  List,
  ListProps,
  ReferenceField,
  SavedQueriesList,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { formatDate } from "../utils/common";
import { AsideStyledCard } from "./components/common";

const Aside = () => (
  <AsideStyledCard>
    <CardContent>
      <FilterLiveSearch source="userId" label="用户id" alwaysOn />
      <SavedQueriesList />
    </CardContent>
  </AsideStyledCard>
);

export const PushHistoryList: React.FC<ListProps> = (props) => (
  <List {...props} aside={<Aside />}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="userId" label="用户id" />
      <ReferenceField
        source="userId"
        reference="fleetAssignments/drivers"
        label="司机"
      >
        <FunctionField
          render={(record: any) => (record.name ? record.name : record.phone)}
        />
      </ReferenceField>
      <TextField source="content" label="推送内容" />
      <FunctionField
        label="推送时间"
        sortBy="timestamp"
        render={(record: any) => formatDate(record.timestamp)}
      />
      <TextField source="type" label="推送类型" />
      <TextField source="status" label="推送状态" />
    </Datagrid>
  </List>
);

export const PushHistoryShow: React.FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField
        source="userId"
        reference="fleetAssignments/drivers"
        label="司机"
      >
        <FunctionField
          render={(record: any) => (record.name ? record.name : record.phone)}
        />
      </ReferenceField>
      <TextField source="content" label="推送内容" />
      <FunctionField
        label="推送时间"
        render={(record: any) => formatDate(record.timestamp)}
      />
      <TextField source="type" label="推送类型" />
      <TextField source="source" label="资源渠道" />
      <TextField source="status" label="推送状态" />
    </SimpleShowLayout>
  </Show>
);
