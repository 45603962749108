import { Identifier } from "react-admin";

// 定义推送策略接口
export interface PushStrategy {
    push(selectedIds: Identifier[], title: string, content: string, variables?: any, template?: any): Promise<any>;
}

// APP推送策略
export class AppPushStrategy implements PushStrategy {
    private dataProvider: any;

    constructor(dataProvider: any) {
        this.dataProvider = dataProvider;
    }

    async push(selectedIds: Identifier[], title: string, content: string) {
        return await this.dataProvider.sendPushNotification({
            selectedIds,
            title,
            content,
        });
    }
}

// 短信推送策略
export class SmsPushStrategy implements PushStrategy {
    private dataProvider: any;

    constructor(dataProvider: any) {
        this.dataProvider = dataProvider;
    }

    async push(selectedIds: Identifier[], title: string, content: string, variables: any, template: string) {
        return await this.dataProvider.sendSmsNotification({
            targetUserIds: selectedIds,
            title,
            template,
            content,
            variables,
        });
    }
}