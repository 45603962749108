export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  if (year >= 2100) {
    // Format time
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  } else {
    // Format date and time
    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  }
};

export const validatePhoneNumber = (phoneNumber: string) => {
  const re = /^1[3-9]\d{9}$/;
  return re.test(phoneNumber);
};