import { Card, Grid, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent/CardContent";
import * as React from "react";
import { ReactNode } from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleShowLayout,
  Show,
  FunctionField,
  FilterLiveSearch,
  SavedQueriesList,
  useShowController,
  ReferenceField,
} from "react-admin";
import { Cargo } from "../models/Cargo";
import { Address, User } from "../models/common";
import { Waybill } from "../models/Waybill";
import { formatDate } from "../utils/common";
import { CargoCard } from "./components/cargo-card";
import {
  AsideStyledCard,
  CargoNameWithImageField,
  UserAvatarWithName,
} from "./components/common";

const Aside = () => (
  <AsideStyledCard>
    <CardContent>
      <FilterLiveSearch source="cargo.name" label="货物名称" alwaysOn />
      <FilterLiveSearch source="truck.plate" label="货车牌照" alwaysOn />
      <SavedQueriesList />
    </CardContent>
  </AsideStyledCard>
);

export const WaybillList: React.FC = (props) => (
  <List {...props} aside={<Aside />} sort={{ field: "id", order: "DESC" }}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" label="运单号" />
      <ReferenceField
        reference="users"
        label="货主"
        source="cargo.owner"
        sortBy="name"
      >
        <FunctionField
          render={(record: User) => <UserAvatarWithName record={record} />}
        />
      </ReferenceField>
      <FunctionField
        label="货物名称"
        sortBy="cargo.name"
        render={(record: Waybill) => (
          <CargoNameWithImageField cargo={record.cargo} />
        )}
      />
      <FunctionField
        label="发布/更新时间"
        sortBy="cargo.updatedAt"
        render={(record: Waybill) => formatDate(record.cargo.updatedAt)}
      />
      <FunctionField
        label="装车时间"
        sortBy="cargo.expect_departure_at"
        render={(record: Waybill) =>
          formatDate(record.cargo.expect_departure_at)
        }
      />
      <FunctionField
        label="发→收"
        render={(record: Cargo) => `${record.from.city}→ ${record.to.city}`}
      />
      <TextField source="truck.plate" label="货车牌照" />
      <TextField source="truck.type" label="货车类型" />
    </Datagrid>
  </List>
);

interface CardWithContentProps {
  children: ReactNode;
}

const CardWithContent = ({ children }: CardWithContentProps) => (
  <Card sx={{ maxWidth: 600 }}>
    <CardContent>{children}</CardContent>
  </Card>
);

export const WaybillShow: React.FC = (props) => {
  const { record } = useShowController(props);
  record.cargo.from = record.from as Address;
  record.cargo.to = record.to as Address;
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <CargoCard cargo={record?.cargo} />
          </Grid>
          <Grid item xs={12} md={8}>
            <CardWithContent>
              <Typography variant="h5" component="div">
                <TextField
                  source="truck.plate"
                  label="货车牌照"
                  record={record}
                />
              </Typography>
              <Typography variant="h5" component="div">
                <TextField
                  source="truck.type"
                  label="货车类型"
                  record={record}
                />
              </Typography>
              <Typography variant="h5" component="div">
                <TextField
                  source="truck.phone"
                  label="司机号码"
                  record={record}
                />
              </Typography>
            </CardWithContent>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};
