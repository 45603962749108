import React from "react";
import { Identifier, useNotify, useUnselectAll } from "react-admin";
import { Cargo } from "../../../models/Cargo";
import { validatePhoneNumber } from "../../../utils/common";
import { PushStrategy } from "../../../utils/push-strategy";
import {
  DEFAULT_PHONE_NUMBER,
  DEFAULT_SMS_TEMPLATE,
  DEFAULT_TITLE,
  DEFAULT_TRUCKS_INFO,
  generateSmsContent,
  handlePushErrors,
} from "./utils";

export const usePushNotification = (
  selectedIds: Identifier[],
  selectedCargo: Cargo,
  handleClose: () => void,
  pushStrategy: PushStrategy,
  selectedPushStrategy: string
) => {
  const notify = useNotify();
  const unselectAll = useUnselectAll("schedulings");
  const [title, setTitle] = React.useState(DEFAULT_TITLE);
  const [appPushContent, setAppPushContent] = React.useState("");
  const [smsContent, setSmsContent] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState(DEFAULT_PHONE_NUMBER);
  const [smsVariables, setSmsVariables] = React.useState({});
  const [smsTemplate, setSmsTemplate] = React.useState(DEFAULT_SMS_TEMPLATE);
  const [trucksInfo, setTrucksInfo] = React.useState(DEFAULT_TRUCKS_INFO);

  const defaultAppPushContent = `有一单从${selectedCargo?.from.city}发往${selectedCargo?.to.city}的货物(${selectedCargo?.name})，邀请您接单承运。`;

  React.useEffect(() => {
    const { smsContent, smsVariables, trucks } = generateSmsContent(
      selectedCargo,
      phoneNumber,
      smsTemplate,
      trucksInfo
    );
    setSmsContent(smsContent);
    setAppPushContent(defaultAppPushContent);
    setSmsVariables(smsVariables);
    setTrucksInfo(trucks);
  }, [
    selectedCargo,
    phoneNumber,
    smsTemplate,
    trucksInfo,
    defaultAppPushContent,
  ]);

  const handlePush = React.useCallback(async () => {
    if (selectedPushStrategy === "sms" && !validatePhoneNumber(phoneNumber)) {
      notify("invalidPhoneNumber", { type: "error" });
      return;
    }

    const pushResults = await pushStrategy.push(
      selectedIds,
      title,
      selectedPushStrategy !== "sms" ? appPushContent : smsContent,
      selectedPushStrategy === "sms" ? smsVariables : undefined,
      selectedPushStrategy === "sms" ? smsTemplate : 4
    );

    handlePushErrors(pushResults, notify);

    unselectAll();
    handleClose();
  }, [
    selectedIds,
    title,
    appPushContent,
    smsContent,
    smsVariables,
    smsTemplate,
    phoneNumber,
    selectedPushStrategy,
    notify,
    unselectAll,
    handleClose,
    pushStrategy,
  ]);

  return {
    title,
    setTitle,
    appPushContent,
    setAppPushContent,
    handlePush,
    smsContent,
    setSmsContent,
    smsTemplate,
    setSmsTemplate,
    phoneNumber,
    setPhoneNumber,
    trucksInfo,
    setTrucksInfo,
  };
};
