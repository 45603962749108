import { CardContent } from "@mui/material";
import {
  AutocompleteArrayInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FilterLiveSearch,
  List,
  ReferenceArrayInput,
  ReferenceManyField,
  SavedQueriesList,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";
import { SourceType } from "../../models/common";
import { AsideStyledCard } from "../components/common";

const fieldLabels = {
  name: "姓名",
  phone: "手机号码",
};

export const MSDriverCreate = (props: any) => (
  <Create
    {...props}
    transform={(data: any) => ({
      ...data,
      source: SourceType.OfflineCollection,
    })}
  >
    <SimpleForm>
      <TextInput source="name" label={fieldLabels.name} />
      <TextInput source="phone" label={fieldLabels.phone} />
    </SimpleForm>
  </Create>
);

const Aside = () => (
  <AsideStyledCard>
    <CardContent>
      <FilterLiveSearch source="name" label="姓名" alwaysOn />
      <FilterLiveSearch source="phone" label="手机号" alwaysOn />
      <SavedQueriesList />
    </CardContent>
  </AsideStyledCard>
);

export const MSDriverList = (props: any) => (
  <List {...props} aside={<Aside />}>
    <Datagrid>
      <TextField source="name" label={fieldLabels.name} />
      <TextField source="phone" label={fieldLabels.phone} />
      <ReferenceManyField
        label="货车"
        reference={`msDrivers/trucks`}
        target="driverId"
      >
        <SingleFieldList linkType={false}>
          <ChipField source="licensePlate" />
        </SingleFieldList>
      </ReferenceManyField>
      <EditButton />
    </Datagrid>
  </List>
);

const filterToQuery = (searchText: any) => ({
  licensePlate: `%${searchText}%`,
});

export const MSDriverEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" label={fieldLabels.name} />
      <TextInput source="phone" label={fieldLabels.phone} />
      <ReferenceManyField
        label="货车"
        reference={`msDrivers/trucks`}
        target="fleetId"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="licensePlate" label="车牌" />
          <TextField source="plateColor" label="车牌颜色" />
          <TextField source="truckType" label="车型" />
          <TextField source="truckLength" label="车长" />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceArrayInput source="trucks" reference="msTrucks">
        <AutocompleteArrayInput
          fullWidth
          optionText="licensePlate"
          label="添加货车"
          filterToQuery={filterToQuery}
          noOptionsText="无选项"
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const MSDriverShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label={fieldLabels.name} />
      <TextField source="phone" label={fieldLabels.phone} />
    </SimpleShowLayout>
  </Show>
);
