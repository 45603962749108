import { useMemo, useState } from "react";
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  Pagination,
  TextField,
  useGetList,
  useList,
} from "react-admin";
import { Cargo } from "../../../models/Cargo";
import { SchedulingBulkActionButtons } from "../../components/drivers-scheduling/actions";
import PushRecord from "../../components/push-record";
import { CustomField, SourceIcon } from "./common";

export const FleetMatchingDrivers = ({
  selectedCargo,
}: {
  selectedCargo: Cargo;
}) => {
  const resource = `schedulings/drivers/cargo/${selectedCargo?.id}`;

  const availableStrategies = ["sms"];

  const filter = useMemo(
    () => ({
      cargoId: selectedCargo?.id,
    }),
    [selectedCargo?.id]
  );

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, total, isLoading } = useGetList(resource, {
    pagination: { page: page, perPage: perPage },
    sort: { field: "id", order: "ASC" },
    filter: { driver: filter },
  });

  const listContext = useList({ data, isLoading, perPage, page });

  return (
    <ListContextProvider value={{ ...listContext, total: total || 0 }}>
      <Datagrid
        bulkActionButtons={
          <SchedulingBulkActionButtons
            initialPushStrategy="sms"
            availableStrategies={availableStrategies}
          />
        }
      >
        <TextField source="driver.name" label="名称" />
        <TextField source="driver.phone" label="手机" />
        <CustomField source="truck.licensePlate" label="车牌" />
        <CustomField source="truck.plateColor" label="车牌颜色" />
        <CustomField source="truck.truckType" label="车型" />
        <CustomField source="truck.truckLength" label="车长" />
        <FunctionField
          label="推送记录"
          render={(record: any) => (
            <PushRecord userId={record.driver._id} showAppPushLength={false} />
          )}
        />
        <SourceIcon label="渠道标记" />
      </Datagrid>
      <Pagination page={page} setPage={setPage} setPerPage={setPerPage} />
    </ListContextProvider>
  );
};
