import { Typography, TextField, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Loading, useDataProvider } from "react-admin";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { UserAvatarWithName } from "../components/common";
import { LineChart } from "@mui/x-charts/LineChart";

interface RecordData {
  date: string;
  total: number;
  published: number;
  offShelf: number;
  timeOut: number;
  abnormal: number;
}

const UserCargoStatsShow: React.FC = () => {
  const { id } = useParams();
  const dataProvider = useDataProvider();
  const theme = useTheme();
  const [days, setDays] = useState<number>(7);
  const { data, isLoading, error, refetch } = useQuery(
    ["user-cargo-stats", id, days],
    () => dataProvider.fetchUserCargoStats(id, days)
  );

  useEffect(() => {
    refetch();
  }, [days, refetch]);

  if (isLoading) return <Loading />;
  if (error) {
    return <p>ERROR</p>;
  }
  if (!data) return <p>无数据</p>;
  const chartData = data.statistics
    ? data.statistics.map((record: RecordData) => ({
        date: record.date,
        total: record.total,
        published: record.published,
        offShelf: record.offShelf,
        timeOut: record.timeOut,
        abnormal: record.abnormal,
      }))
    : [];
  return (
    <div>
      <TextField
        label="天数"
        type="number"
        value={days}
        onChange={(event) => setDays(Number(event.target.value))}
        inputProps={{ min: 1, max: 30 }}
      />
      {chartData.length === 0 ? (
        <Typography variant="h6" align="center">
          无数据
        </Typography>
      ) : (
        <LineChart
          series={[
            {
              data: chartData.map((item: RecordData) => item.total),
              label: "总数",
              area: true,
              color: theme.palette.primary.main, // 使用主题色
            },
            {
              data: chartData.map((item: RecordData) => item.published),
              label: "已发布",
              area: true,
              color: theme.palette.secondary.main, // 使用次色
            },
            {
              data: chartData.map((item: RecordData) => item.offShelf),
              label: "已下架",
              area: true,
              color: theme.palette.primary.light, // 使用其他主题色
            },
            {
              data: chartData.map((item: RecordData) => item.timeOut),
              label: "已过期",
              area: true,
              color: theme.palette.secondary.light, // 使用其他主题色
            },
            {
              data: chartData.map((item: RecordData) => item.abnormal),
              label: "异常",
              area: true,
              color: theme.palette.error.main, // 使用错误色
            },
          ]}
          height={290}
          xAxis={[
            {
              data: chartData.map((item: RecordData) => item.date),
              scaleType: "band",
            },
          ]}
          margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
        />
      )}
      <UserAvatarWithName record={data} />
    </div>
  );
};

export default UserCargoStatsShow;
