import {
  Datagrid,
  Pagination,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { CustomField, SourceIcon } from "./common";

export const FleetShow: React.FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="车队名称" />
      <TextField source="description" label="车队描述" />
      <ReferenceManyField
        label="站点"
        reference={`fleets/stations`}
        target="fleetId"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" label="站点名称" />
          <TextField source="location.province" label="省份" />
          <TextField source="location.city" label="城市" />
          <TextField source="location.district" label="区域" />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="司机"
        reference={`fleets/assignment`}
        target="fleetId"
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="driver.name" label="名称" />
          <TextField source="driver.phone" label="手机" />
          <CustomField source="truck.licensePlate" label="车牌" />
          <CustomField source="truck.plateColor" label="车牌颜色" />
          <CustomField source="truck.truckType" label="车型" />
          <CustomField source="truck.truckLength" label="车长" />
          <SourceIcon label="渠道标记" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);
